import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { OpenQueueWrapper } from "./FloatingStatusQueue.styles";
import { Drawer } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { filterInprogressItems } from "../../utils/utils";
import { useState } from "react";
import StatusQueue from "../StatusQueue/StatusQueue";
import { useLocation } from "react-router-dom";
// import Status from "../common/Status";

const FloatingStatusQueue = ({ queueData = [] }) => {
  const inProgressItems = filterInprogressItems(queueData);
  const [showQueue, setShowQueue] = useState(false);
  let location = useLocation();

  useEffect(() => {
    setShowQueue(false);
  }, [location]);
  return (
    <>
      <OpenQueueWrapper type="primary" onClick={() => setShowQueue(true)}>
        <>
          <DoubleLeftOutlined className="upIcon" />
          <span className="desktopText"> Open</span> Queue (
          {inProgressItems.length})
        </>
      </OpenQueueWrapper>
      <Drawer
        title="Generation Queue"
        placement="bottom"
        closable
        height={"calc(100% - 200px)"}
        onClose={() => setShowQueue(false)}
        open={showQueue}
      >
        <StatusQueue data={queueData} />
      </Drawer>
    </>
  );
};

FloatingStatusQueue.propTypes = {};

FloatingStatusQueue.defaultProps = {};

export default FloatingStatusQueue;

// <TotalTaskWrapper>
//        <div
//          style={{
//            display: "flex",
//            alignItems: "center",
//            justifyContent: "space-between",
//            height: "100%",
//            maxWidth: "300px",
//            margin: "0 auto",
//          }}
//        >
//          <StatLabelLarge color={token.colorPrimary}>
//            <ExperimentOutlined size={12} /> Total Running Tasks
//          </StatLabelLarge>
//          <StatItem>
//            <div className="statDetails">
//              <Text strong style={{ fontSize: "20px", marginLeft: "5px" }}>
//                8
//              </Text>
//            </div>
//          </StatItem>
//        </div>
//      </TotalTaskWrapper>
//      <MostRecentWrapper>
//        <StatLabel color={statusColor[status]}>Most Recent</StatLabel>
//        <StatItem>
//          <Status status={status} size={11} />
//          <div style={{ margin: "0 20px 0 7px", display: "flex" }}>
//            <Text style={{ fontSize: "12px", flex: "0 0 auto" }} strong>
//              Fig Model 1 :
//            </Text>
//            <Text
//              style={{ fontSize: "12px", marginLeft: "7px", minWidth: "0" }}
//              ellipsis
//              copyable
//            >
//              Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
//              ipsa asperiores sequi consectetur reprehenderit soluta facere
//              laudantium! At eius praesentium deserunt laboriosam, minus
//              quisquam impedit odio et fuga voluptas accusamus.
//            </Text>
//          </div>
//          <Button
//            type="link"
//            size="small"
//            style={{ fontSize: "12px", padding: 0 }}
//          >
//            View
//          </Button>
//        </StatItem>
//      </MostRecentWrapper>
