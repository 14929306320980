import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpAuth } from "../../services/http";

// export const creditsAction = createAsyncThunk(
//   "credits/Action",
//   async (arg, { rejectWithValue }) => {
//     try {
//       const data = await asyncFunction();
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const getAvailableCreditsAction = createAsyncThunk(
  "credits/availableCredits",
  async (arg, { rejectWithValue }) => {
    try {
      const data = await httpAuth.get("/api/users/available_credits/");
      return data.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCreditPlansAction = createAsyncThunk(
  "credits/creditPlans",
  async (params = { currency: "usd" }, { dispatch, rejectWithValue }) => {
    try {
      const currency = params?.currency || "usd";
      localStorage.setItem("userCurrency", currency);
      const response = await httpAuth.get("/api/payments/pricing-details", {
        params,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const buyCredits = async (creditData) => {
  try {
    const { data } = await httpAuth.post(
      `/api/payments/buy-credits/`,
      creditData
    );

    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message;
    } else {
      return error.message;
    }
  }
};
