import { Button } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 10px 0;
  background-color: ${(p) => p.bgColor};
  /* border-top: 1px solid rgba(97, 179, 250, 0.21); */
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  box-shadow: 0 -6px 16px 0 rgba(0, 0, 0, 0.08),
    0 -3px 6px -4px rgba(0, 0, 0, 0.12), 0 -9px 28px 8px rgba(0, 0, 0, 0.05);

  .statusCloseIcon {
    position: absolute;
    top: -7px;
    right: 10px;
    cursor: pointer;
  }
`;

// export const StatLabel = styled.div`
//   font-size: 10px;
//   color: ${({ color }) => color || "#fff"};
//   text-transform: uppercase;
// `;

// export const StatLabelLarge = styled(StatLabel)`
//   font-size: 15px;
// `;

// export const StatItem = styled.div`
//   /* margin-top: 5px; */
//   display: flex;
//   align-items: center;

//   .statDetails {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     align-items: center;
//   }
// `;

// export const MostRecentWrapper = styled.div`
//   flex: 1;
//   padding: 0 20px;
//   border-right: 1px solid rgba(97, 179, 250, 0.21);
//   min-width: 300px;
// `;

// export const TotalTaskWrapper = styled.div`
//   flex: 0 1 400px;
//   padding: 0 20px;
//   border-right: 1px solid rgba(97, 179, 250, 0.21);
// `;

export const OpenQueueWrapper = styled(Button)`
  position: fixed;
  bottom: 0px;
  right: 30px;
  flex: 0 1 200px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  z-index: 990;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -6px 16px 0 rgba(0, 0, 0, 0.08),
    0 -3px 6px -4px rgba(0, 0, 0, 0.12), 0 -9px 28px 8px rgba(0, 0, 0, 0.05);

  .upIcon {
    transform: rotate(90deg);
  }

  @media (max-width: 600px) {
    padding: 15px;
    .desktopText {
      display: none;
    }
  }
`;
