import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Profile.styles";
import styled from "styled-components";
import { Button, Card, Divider, Form, Input, Space, Typography } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { HIDE_SIGNUP } from "../../config";

const { Title, Text } = Typography;

const Wrapper = styled.div`
  max-width: 800px;
  margin: auto;
  width: 100%;
`;

const Field = styled.div`
  display: flex;
  padding: 15px 0;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Label = styled(Text)`
  width: 25%;
  font-weight: 600;
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
    margin-bottom: 10px;
  }
`;

const FieldValue = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

const Profile = ({
  name = "",
  email,
  available_credits: availableCredits,
  onBuyCredit,
  onProfileChange,
  onResetPassword,
}) => {
  const [editNameState, setEditNameState] = useState(false);
  const [form] = Form.useForm();
  const handleNameChange = (data) => {
    if (data.name && data.name !== name) {
      onProfileChange(data);
    }
    setEditNameState(false);
  };

  const cancelNameChange = () => {
    setEditNameState(false);
    form.resetFields();
    form.setFieldsValue({ name });
  };

  return (
    <Wrapper bordered={false}>
      <Title level={1}>Profile</Title>
      <Divider orientationMargin="0"></Divider>
      <Card>
        <Field>
          <Label>Name</Label>
          <FieldValue>
            <Form
              form={form}
              name="update profile"
              layout="inline"
              onFinish={handleNameChange}
              style={{ width: "100%" }}
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
                style={{ flex: "1" }}
                initialValue={name}
              >
                <Input
                  style={{ flex: "1" }}
                  placeholder="Name"
                  disabled={!editNameState}
                />
              </Form.Item>
              {editNameState ? (
                <Space>
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon={<CheckOutlined />}
                  />
                  <Button onClick={cancelNameChange} icon={<CloseOutlined />} />
                </Space>
              ) : (
                <Button
                  onClick={() => setEditNameState(true)}
                  type="text"
                  icon={<EditOutlined />}
                />
              )}
            </Form>
          </FieldValue>
        </Field>

        <Field>
          <Label>Email</Label>
          <FieldValue>
            <Input value={email} disabled />
          </FieldValue>
        </Field>

        {!HIDE_SIGNUP ? (
          <Field>
            <Label>Reset Password</Label>
            <FieldValue>
              <Button onClick={onResetPassword}>Send Reset Email</Button>
            </FieldValue>
          </Field>
        ) : null}

        <Field>
          <Label>Available Credits</Label>
          <FieldValue>
            <Space size="large" align="center">
              <Text style={{ fontWeight: "700" }}>{availableCredits}</Text>
              <Button type="primary" size="small" onClick={onBuyCredit}>
                Buy More
              </Button>
            </Space>
          </FieldValue>
        </Field>
      </Card>
    </Wrapper>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
