import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./CategoryGrid.styles";
import { Link } from "react-router-dom";
import Card from "../ModelCardWithActions";
import Pagination from "../Pagination/Pagination";
import { CardWrapper, Grid } from "../common/GridWrapper";

const CategoryGrid = ({ items = [], withPagination, paginationProps }) => {
  return (
    <>
      <Grid itemWindowWidthMap={{ 0: 150, 600: 240 }}>
        {items.map((item, index) => (
          <CardWrapper>
            <Card
              key={index}
              model={item}
              image={item.banner_image}
              title={item.name}
              id={item.id}
              favorite={item.favorite}
            />
          </CardWrapper>
        ))}
      </Grid>
      {withPagination ? <Pagination {...paginationProps} /> : null}
    </>
  );
};

CategoryGrid.propTypes = {};

CategoryGrid.defaultProps = {};

export default CategoryGrid;
