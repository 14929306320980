import axios from "axios";
import { API_BASE_URL } from "../config";
import { auth } from "../firebase";

axios.defaults.headers.common["Content-Type"] = "application/json";
const errorInterceptor = function (error) {
  console.error("Response error: ", error);
  if (error?.response?.data) {
    return Promise.reject({
      message: Object.keys(error.response.data).reduce((acc, key) => {
        acc += error.response.data[key];
        return acc;
      }, ""),
      response: error.response,
    });
  }
  return Promise.reject(error);
};

const successInterceptor = function (response) {
  return response;
};

const http = axios.create({
  baseURL: API_BASE_URL,
});
http.interceptors.response.use(successInterceptor, errorInterceptor);

export default http;

export const httpAuth = axios.create({
  baseURL: API_BASE_URL,
});

httpAuth.interceptors.request.use(async (config) => {
  const userToken = await auth?.currentUser?.getIdToken();
  if (!config.headers["Authorization"] && userToken) {
    config.headers["Authorization"] = `Token ${userToken}`;
  }

  return config;
});
httpAuth.interceptors.response.use(successInterceptor, errorInterceptor);
