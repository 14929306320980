import { App, Button, Form, Input, Typography } from "antd";
import styled from "styled-components";
import { useAxios } from "../../../hooks/axios";
import { CheckCircleFilled } from "@ant-design/icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SignupInputWrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin: auto;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

const FormItemWrapper = styled.div`
  border-radius: 27px;
  color: #000;
  display: flex;
  background-color: #fff;
  align-items: stretch;
  justify-content: space-between;
  height: 54px;
  border: 2px solid #565656;
  padding: 2px 2px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    border-radius: 0px;
    background-color: transparent;
    align-items: center;
    width: 100%;
    gap: 10px;
    height: auto;
    border: none;
  }
`;

const StyledInput = styled(Input)`
  border: none;
  outline: none;
  flex: 1;
  padding: 8px 16px;
  border-radius: 25px 0 0 25px;
  width: 100%;
  font-size: 16px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  @media screen and (max-width: 768px) {
    line-height: 32px;
    border-radius: 25px;
    border: 1px solid #565656;
  }
`;

const SignupInput = ({ style, className, ...props }) => {
  const {
    response,
    error,
    loading,
    fetchData: signup,
  } = useAxios(
    {
      url: `api/early-access/`,
      method: "post",
    },
    {
      errorNotification: false,
    }
  );
  const onSubmit = (data) => {
    signup({ data });
  };

  const { message } = App.useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (response) {
      navigate("/join");
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      if (error?.response?.status === 409) {
        message.error("This email id already exists in the system.");
      } else {
        message.error("Something went wrong!");
      }
    }
  }, [error]);

  return (
    <>
      <SignupInputWrapper style={style} className={className}>
        <Form
          name="signup"
          autoComplete="off"
          onFinish={onSubmit}
          disabled={loading}
          validateTrigger="onFinish"
        >
          <Form.Item>
            <FormItemWrapper>
              <Form.Item
                name="email"
                noStyle
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <StyledInput placeholder="Your email" />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  size="large"
                  style={{
                    height: "auto",
                    background: "linear-gradient(-45deg, #e73c7e, #6633d9)",
                    border: "1px solid #fff",
                    minHeight: "45px",
                  }}
                >
                  {loading ? "Submitting..." : "Get Early Access"}
                </Button>
              </Form.Item>
            </FormItemWrapper>
          </Form.Item>
        </Form>
      </SignupInputWrapper>
    </>
  );
};

export default SignupInput;
