import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";

//FIXME: read from env file
const firebaseConfig = {
  apiKey: "AIzaSyBOn_PnB1X0OU8clN7nHUporVl30he1V9A",
  authDomain: "dk-pixeous.firebaseapp.com",
  projectId: "dk-pixeous",
  storageBucket: "dk-pixeous.appspot.com",
  messagingSenderId: "88237995332",
  appId: "1:88237995332:web:7c4dfdbfa693dcc9b89311",
  measurementId: "G-Q7SGDYG37F",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    return res.user.accessToken;
  } catch (err) {
    console.error(err);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  const res = await signInWithEmailAndPassword(auth, email, password);
  return { token: res.user.accessToken };
};

const registerWithEmailAndPassword = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  //sendEmailVerification(userCredential.user);
  return userCredential.user.accessToken;
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  logout,
};
