import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./HistoryContainer.styles";
import { Divider, Result, Typography } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useGetHistory } from "../../hooks/modelHooks";
import { Grid } from "../../components/common/GridWrapper";
import Pagination from "../../components/Pagination";
import Card from "../../components/Card";
import Loader from "../../components/Loader/Loader";

const { Title } = Typography;

const QUERY_PARAMS = {
  PAGE: "page",
};

const HistoryContainer = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get(QUERY_PARAMS.PAGE) || 1)
  );
  const { getHistory, data } = useGetHistory();
  const { loading, data: response } = data;

  const updateSearchParams = (page) => {
    setSearchParams(
      `?${new URLSearchParams({
        page,
      })}`
    );
  };

  useEffect(() => {
    const page = Number(searchParams.get(QUERY_PARAMS.PAGE) || 1);
    getHistory(page);
    setCurrentPage(page);
  }, [searchParams]);

  const onPageChange = (page) => {
    updateSearchParams(page);
  };

  let result = null;
  const filteredResult = response?.results?.length ? response?.results : [];

  useEffect(() => {
    if (response?.results?.length && filteredResult.length === 0) {
      if (currentPage > 1) {
        onPageChange(currentPage - 1);
      }
      if (currentPage === 1 && response.count > response.page_size) {
        onPageChange(1);
      }
    }
  }, [filteredResult]);

  if (loading) {
    result = <Loader style={{ minHeight: "300px" }} />;
  } else if (filteredResult.length) {
    result = (
      <>
        <Grid itemWindowWidthMap={{ 0: 150, 600: 240 }}>
          {filteredResult.map((model, index) => (
            <Link to={`/preview/${model.id}`} key={index}>
              <Card
                images={model.imagesArr}
                title={model?.aimodel?.name || ""}
                description={model.prompt}
              />
            </Link>
          ))}
        </Grid>
        <Pagination
          current={currentPage}
          pageSize={response.page_size}
          total={response.count}
          onChange={onPageChange}
        />
      </>
    );
  } else {
    result = <Result status="404" title="Oops!" subTitle="No results found." />;
  }

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Title level={3}>History</Title>
      </Divider>
      {result}
    </>
  );
};

HistoryContainer.propTypes = {};

HistoryContainer.defaultProps = {};

export default HistoryContainer;
