import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./ModelPreview.styles";
import {
  Card,
  Skeleton,
  Typography,
  Form,
  Button,
  theme,
  Row,
  Col,
  Space,
  Collapse,
} from "antd";
import styled from "styled-components";
import { useGetModelPreview } from "./hooks";
import { useDispatch, useSelector } from "react-redux";
import ImagePreviewContainer from "../ImagePreview/ImagePreviewContainer";
import { useErrorNotification } from "../../hooks/common";
import { useIsLogin } from "../../hooks/user";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import LoadingBar from "react-top-loading-bar";
import ProgressTimer from "../ProgressTimer/ProgressTimer";
import { getAvailableCreditsCount } from "../../features/credits/creditsSlice";
import { showBuyCreditModal } from "../../features/global/globalSlice";
import TagInput from "../TagInput/TagInput";
import UploadImage from "./UploadImage";
import { useAxios } from "../../hooks/axios";
import BorderedDiv from "../common/BorderedDiv";
import { formatGenerateImageFormValue } from "../../utils/utils";
import Slider from "../common/Slider";
import { DEFAULT_GUIDANCE_SCALE } from "../../constants";
import { AdvanceSettingsCollapse } from "../common/FormCommon";

const { Text } = Typography;
const { useToken } = theme;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const ImagesLayoutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const LoaderLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const ModelPreview = ({
  modelId,
  prompt: promptProp = "",
  onSubmitPrompt,
  onImageResponse,
  withImage = false,
  negativePrompt: negativePromptProp,
  guidanceScale: guidanceScaleProp,
}) => {
  const [promptInput, setPromptInput] = useState("");
  const progressRef = useRef(null);
  const isLoggedIn = useIsLogin();
  const [form] = Form.useForm();
  const { token } = useToken();
  const dispatch = useDispatch();
  const { data: availableCredits, loading: creditCountLoading } = useSelector(
    getAvailableCreditsCount
  );

  const {
    loading,
    error,
    getModelResponse,
    onTimeout,
    response = [],
    promptId,
  } = useGetModelPreview(
    progressRef,
    onSubmitPrompt,
    onImageResponse,
    withImage
  );

  const resetPrompts = (data) => {
    form.resetFields();
    const fieldValues = formatGenerateImageFormValue(data);
    form.setFieldsValue(fieldValues);
  };

  useEffect(() => {
    resetPrompts({
      prompt: promptProp || "",
      image: {},
      negativePrompt: negativePromptProp || "",
      guidanceScale: guidanceScaleProp || DEFAULT_GUIDANCE_SCALE,
    });
  }, [promptProp, negativePromptProp, guidanceScaleProp]);

  const handleSubmit = (data) => {
    const { prompt: newPrompt, image, negativePrompt, guidanceScale } = data;
    setPromptInput("");
    const finalPrompt = newPrompt.join(",");
    if (newPrompt.length) {
      getModelResponse({
        aimodel: modelId,
        prompt: finalPrompt,
        negative_prompt: negativePrompt ? negativePrompt.join(",") : null,
        image: image ? image[0].originFileObj : null,
        guidance_scale: guidanceScale,
      });
    }
    resetPrompts(data);
  };

  const {
    response: promptSuggestions,
    fetchData: fetchPromptSuggestions,
    setResponse: updateSuggestions,
  } = useAxios({
    url: `/api/prompt/prompt_suggestions/`,
    method: "post",
  });

  const handleTagSubmit = (tags) => {
    fetchPromptSuggestions({
      data: {
        prompt: tags.join(","),
      },
    });
  };

  const refreshPromptSuggestions = () => {
    const prompts = form.getFieldValue("prompt");
    handleTagSubmit(prompts);
  };

  const addSuggestionToPrompt = (suggestion) => {
    const { prompt, ...other } = form.getFieldsValue();
    let currentPrompts = [...prompt];
    if (promptInput) {
      const lastPrompt = currentPrompts[currentPrompts.length - 1];
      if (lastPrompt === promptInput) {
        currentPrompts = currentPrompts.slice(0, -1);
      }
    }
    const updatedPrompts = [...currentPrompts, suggestion];
    form.resetFields();
    form.setFieldsValue({ prompt: updatedPrompts, ...other });
    const filteredSuggestions = promptSuggestions.result.filter(
      (item) => item !== suggestion
    );
    updateSuggestions({
      ...promptSuggestions,
      result: filteredSuggestions,
    });
  };

  useErrorNotification(error);

  const getErrorMsg = () => {
    if (!error || error === "credits_exhausted") {
      if (!isLoggedIn) {
        return (
          <Text>
            Please <Link to="/login">Login</Link> to run generate images
          </Text>
        );
      } else if (
        error === "credits_exhausted" ||
        (!availableCredits && !creditCountLoading)
      ) {
        return (
          <Text>
            Insufficient credits. Please{" "}
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => dispatch(showBuyCreditModal())}
            >
              buy credits
            </Button>{" "}
            to generate the images.
          </Text>
        );
      }
    }
    return <Text type="danger">{error}</Text>;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  let errorMsg = getErrorMsg();

  return (
    <Card
      bodyStyle={{ position: "relative" }}
      title={
        <Form
          form={form}
          initialValues={{ prompt: [], guidanceScale: DEFAULT_GUIDANCE_SCALE }}
          onFinish={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          {withImage ? (
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: "Image is required" }]}
            >
              <UploadImage />
            </Form.Item>
          ) : null}

          <Form.Item
            name={"prompt"}
            rules={[{ required: true, message: "Prompt is required" }]}
          >
            <TagInput
              placeholder="Enter Prompt"
              error={form.getFieldError("prompt")}
              onInputChange={setPromptInput}
              valueInput={promptInput}
              onTagSubmit={handleTagSubmit}
            />
          </Form.Item>

          {promptSuggestions?.result.length ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text>Suggestions:</Text>
                <Button
                  type="link"
                  size="small"
                  style={{ padding: 0 }}
                  onClick={refreshPromptSuggestions}
                >
                  Refresh
                </Button>
              </div>
              <BorderedDiv style={{ marginBottom: "16px" }}>
                <Space wrap>
                  {promptSuggestions?.result.map((item) => (
                    <Button
                      key={item}
                      type="primary"
                      ghost
                      size="small"
                      onClick={() => addSuggestionToPrompt(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </Space>
              </BorderedDiv>
            </>
          ) : null}
          <AdvanceSettingsCollapse
            bordered={false}
            defaultActiveKey={[negativePromptProp ? "1" : null]}
          >
            <Collapse.Panel header="Advance Settings" key="1" forceRender>
              <Form.Item name={"negativePrompt"}>
                <TagInput
                  placeholder="Enter Negative Prompt"
                  inputStyles={{ minWidth: "160px" }}
                />
              </Form.Item>
              <Form.Item name="guidanceScale" label="Guidance Scale">
                <Slider
                  marks={{ 0: 0, 100: 100 }}
                  min={0}
                  max={100}
                  step={0.5}
                />
              </Form.Item>
            </Collapse.Panel>
          </AdvanceSettingsCollapse>
          <Row gutter={{ xs: 8, sm: 16 }}>
            <Col xs={24} sm={12}>
              <Form.Item>
                <Button
                  block
                  size="large"
                  onClick={() => form.resetFields()}
                  disabled={!availableCredits || !isLoggedIn || loading}
                >
                  Clear
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!availableCredits || !isLoggedIn || loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
      style={{ width: "100%" }}
    >
      {loading ? (
        <LoadingBar
          height={5}
          color={token.colorPrimary}
          ref={progressRef}
          containerStyle={{ position: "absolute", overflow: "hidden" }}
        />
      ) : null}
      {loading && !error ? (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <ProgressTimer totalTime={25} timeoutAt={60} onTimeout={onTimeout} />
        </div>
      ) : null}
      {errorMsg ? (
        <div style={{ textAlign: "center", fontSize: "20px" }}>{errorMsg}</div>
      ) : null}
      <PreviewWrapper>
        {loading ? (
          <LoaderLayoutWrapper>
            <Skeleton.Image active />
            <Skeleton.Image active />
            <Skeleton.Image active />
            <Skeleton.Image active />
          </LoaderLayoutWrapper>
        ) : (
          <ImagesLayoutWrapper>
            {response.map((imgObj, index) => (
              <ImagePreviewContainer
                key={index}
                image={`${API_BASE_URL}/${imgObj.image}`}
                id={imgObj.id}
                promptId={imgObj.user_prompt}
                previewUrl={`/preview/${imgObj.user_prompt}?item=${index}`}
              />
            ))}
          </ImagesLayoutWrapper>
        )}
      </PreviewWrapper>
    </Card>
  );
};

ModelPreview.propTypes = {};

ModelPreview.defaultProps = {};

export default ModelPreview;
