import { useCallback, useEffect, useState } from "react";
import {
  generateImgToImg,
  getModelGeneratedImages,
  getModelResponse,
} from "../../services/model";
import { useDispatch } from "react-redux";
import { usePolling } from "../../hooks/common";
import { getAvailableCreditsAction } from "../../features/credits/creditsActions";

export function useGetModelPreview(progressRef, onStart, onFinish, withImage) {
  const dispatch = useDispatch();
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [promptId, setPromptId] = useState(null);

  const pollModelGeneratedImages = useCallback((id) => {
    if (id) {
      return getModelGeneratedImages(id).catch((e) => {
        setLoading(false);
        setError(e.message);
        stopPoll();
        progressRef.current && progressRef.current.complete();
      });
    }
  }, []);

  const {
    data: generatedImagesData,
    start: startPoll,
    stop: stopPoll,
  } = usePolling(pollModelGeneratedImages, 5000, promptId);

  useEffect(() => {
    const { data } = generatedImagesData || {};
    if (data?.is_processed) {
      progressRef.current && progressRef.current.complete();
      setResponse(data.images);
      setLoading(false);
      stopPoll();
      onFinish && onFinish();
    }
  }, [generatedImagesData, stopPoll]);

  const initModelResponse = async (data) => {
    try {
      setLoading(true);
      setError("");
      progressRef.current && progressRef.current.continuousStart(10, 2000);

      const request = withImage ? generateImgToImg : getModelResponse;
      const responseData = await request(data);
      const { data: response } = responseData || {};
      if (response?.id) {
        setPromptId(response.id);
        onStart && onStart();
        startPoll();
        dispatch(getAvailableCreditsAction());
      } else {
        console.log("No id found in ", response);
        throw Error("Something went wrong!!");
      }
    } catch (e) {
      console.error("e: ", e);
      progressRef.current.complete();
      setError(e.message);
      setLoading(false);
    }
  };

  const onTimeout = () => {
    stopPoll();
    setLoading(false);
    setError("Servers are busy, Please check the queue in a while.");
  };

  return {
    response,
    error,
    loading,
    onTimeout,
    getModelResponse: initModelResponse,
  };
}
