import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config";
import http, { httpAuth } from "../../services/http";

export const processModelItem = (item) => {
  return {
    favorite: item.is_favorite || false,
    ...item,
    images: item.images.map((imageObj) => `${API_BASE_URL}${imageObj.image}`),
  };
};

export const processModelList = (list) => {
  return list?.map(processModelItem);
};

export const getModelCategoryList = createAsyncThunk(
  "models/getModelCategoryList",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(`/api/aimodels/categories/`);
      return data.map((category) => {
        return {
          ...category,
          aimodels: processModelList(category.aimodels),
        };
      });
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllModelsAction = createAsyncThunk(
  "models/getAllModels",
  async ({ page, type }, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(
        `/api/aimodels/?page=${page}&type=${type}`
      );
      return {
        ...data,
        results: processModelList(data.results),
      };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchModel = createAsyncThunk(
  "models/search",
  async ({ searchText, page = 1 }, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(
        `/api/search?search=${searchText}&page=${page}`
      );
      return {
        ...data,
        results: processModelList(data.results),
      };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const top4Models = createAsyncThunk(
  "models/top4Models",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(`/api/aimodels/top4/`);
      return processModelList(data);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getModelDetail = createAsyncThunk(
  "models/detail",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(`/api/aimodels/${id}/`);
      return {
        ...data,
        imagesArr: data.images.map(
          (imageObj) => `${API_BASE_URL}${imageObj.image}`
        ),
        images: data.images.map((imageObj) => ({
          ...imageObj,
          image: `${API_BASE_URL}${imageObj.image}`,
        })),
      };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getFavoriteAction = createAsyncThunk(
  "models/favorite",
  async (page = 1, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(`/api/aimodels/favorites/`, {
        params: {
          page,
        },
      });
      return {
        ...data,
        results: processModelList(data.results),
      };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addToFavoriteAction = createAsyncThunk(
  "models/addToFavorite",
  async ({ id, favorite }, { rejectWithValue }) => {
    try {
      const url = favorite
        ? "/api/aimodels/add_to_favorite/"
        : "/api/aimodels/remove_from_favorite/";
      await httpAuth.post(url, {
        aimodel_id: id,
      });
      return { id, favorite };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
