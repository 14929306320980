import React from "react";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { CoverImage, StyledCard as Card } from "../common/CardBase";
import { Link, useLocation } from "react-router-dom";

const DownImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
`;

const StyledCard = styled(Card)`
  padding: 0;
  position: relative;

  &:hover {
    ${DownImageOverlay} {
      opacity: 1;
    }
  }
`;

const Image = ({ image, previewUrl, size }) => {
  const location = useLocation();
  return (
    <CoverImage image={image} size={size}>
      <DownImageOverlay>
        <Link to={previewUrl} state={{ backgroundLocation: location }}>
          <Button
            title="Preview"
            type="text"
            icon={<EyeOutlined style={{ fontSize: "20px", color: "#fff" }} />}
            size="large"
          />
        </Link>
      </DownImageOverlay>
    </CoverImage>
  );
};

const ImagePreview = ({ image, flexible, onDownload, size, previewUrl }) => {
  return (
    <StyledCard
      noTitle
      size={size}
      flexible={flexible}
      cover={<Image image={image} size={size} previewUrl={previewUrl} />}
    />
  );
};
export default ImagePreview;
