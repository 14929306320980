export const GENERATION_STATUS = {
  DONE: "done",
  FAILURE: "failure",
  PENDING: "pending",
};

export const statusColor = {
  [GENERATION_STATUS.DONE]: "#00e200",
  [GENERATION_STATUS.FAILURE]: "#ff2A04",
  [GENERATION_STATUS.PENDING]: "#ffaf3d",
};

export const DEFAULT_GUIDANCE_SCALE = 7.5;

export const ERROR_NOTIFICATION_DURATION = 4.5;
