import { createSlice } from "@reduxjs/toolkit";
import {
  getModelCategoryList,
  getAllModelsAction,
  getModelDetail,
  searchModel,
  top4Models,
  addToFavoriteAction,
  getFavoriteAction,
} from "./modelsActions";

const initialState = {
  modelCategory: {
    loading: false,
    data: null,
    error: null,
    success: false,
  },
  allModels: {
    loading: false,
    data: null,
    error: null,
    success: false,
  },
  modelSearchResult: {
    loading: false,
    data: null,
    error: null,
    success: false,
  },
  modelDetail: {
    loading: false,
    data: null,
    error: null,
    success: false,
  },
  top4Models: {
    loading: false,
    data: null,
    error: null,
    success: false,
  },
  favorite: {
    loading: false,
    data: null,
    error: null,
    success: false,
  },
};

const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {},
  extraReducers: {
    [getModelCategoryList.pending]: (state) => {
      state.modelCategory.loading = true;
      state.modelCategory.error = null;
    },
    [getModelCategoryList.fulfilled]: (state, { payload }) => {
      state.modelCategory.loading = false;
      state.modelCategory.success = true;
      state.modelCategory.data = payload;
    },
    [getModelCategoryList.rejected]: (state, { payload }) => {
      state.modelCategory.loading = false;
      state.modelCategory.error = payload;
    },
    [getAllModelsAction.pending]: (state) => {
      state.allModels.loading = true;
      state.allModels.error = null;
    },
    [getAllModelsAction.fulfilled]: (state, { payload }) => {
      state.allModels.loading = false;
      state.allModels.success = true;
      state.allModels.data = payload;
    },
    [getAllModelsAction.rejected]: (state, { payload }) => {
      state.allModels.loading = false;
      state.allModels.error = payload;
    },

    [searchModel.pending]: (state) => {
      state.modelSearchResult.loading = true;
      state.modelSearchResult.error = null;
    },
    [searchModel.fulfilled]: (state, { payload }) => {
      state.modelSearchResult.loading = false;
      state.modelSearchResult.success = true;
      state.modelSearchResult.data = payload;
    },
    [searchModel.rejected]: (state, { payload }) => {
      state.modelSearchResult.loading = false;
      state.modelSearchResult.error = payload;
    },

    [getModelDetail.pending]: (state) => {
      state.modelDetail.loading = true;
      state.modelDetail.error = null;
      state.modelDetail.data = null;
    },
    [getModelDetail.fulfilled]: (state, { payload }) => {
      state.modelDetail.loading = false;
      state.modelDetail.success = true;
      state.modelDetail.data = payload;
    },
    [getModelDetail.rejected]: (state, { payload }) => {
      state.modelDetail.loading = false;
      state.modelDetail.error = payload;
    },
    [top4Models.pending]: (state) => {
      state.top4Models.loading = true;
      state.top4Models.error = null;
      state.top4Models.data = null;
    },
    [top4Models.fulfilled]: (state, { payload }) => {
      state.top4Models.loading = false;
      state.top4Models.success = true;
      state.top4Models.data = payload;
    },
    [top4Models.rejected]: (state, { payload }) => {
      state.top4Models.loading = false;
      state.top4Models.error = payload;
    },
    [getFavoriteAction.pending]: (state) => {
      state.favorite.loading = true;
      state.favorite.error = null;
      state.favorite.data = null;
    },
    [getFavoriteAction.fulfilled]: (state, { payload }) => {
      state.favorite.loading = false;
      state.favorite.success = true;
      state.favorite.data = payload;
    },
    [getFavoriteAction.rejected]: (state, { payload }) => {
      state.favorite.loading = false;
      state.favorite.error = payload;
    },
    [addToFavoriteAction.fulfilled]: (state, { payload: { id, favorite } }) => {
      if (state.top4Models.data) {
        state.top4Models.data = updateFavoriteStatus(
          state.top4Models.data,
          id,
          favorite
        );
      }
      if (state?.modelCategory?.data?.results) {
        state.modelCategory.data.results = updateFavoriteStatus(
          state.modelCategory.data.results,
          id,
          favorite
        );
      }
      if (state.modelSearchResult?.data?.results) {
        state.modelSearchResult.data.results = updateFavoriteStatus(
          state.modelSearchResult.data.results,
          id,
          favorite
        );
      }
      if (state.favorite?.data?.results) {
        state.favorite.data.results = updateFavoriteStatus(
          state.favorite.data.results,
          id,
          favorite
        );
      }
      if (state.allModels?.data?.results) {
        state.allModels.data.results = updateFavoriteStatus(
          state.allModels.data.results,
          id,
          favorite
        );
      }
      if (state.modelDetail?.data && state.modelDetail.data?.id === id) {
        state.modelDetail.data.favorite = favorite;
      }
    },
  },
});

const updateFavoriteStatus = (modelList, id, favorite) => {
  return modelList.map((model) =>
    model.id === id
      ? {
          ...model,
          favorite,
        }
      : model
  );
};

export default modelsSlice.reducer;
