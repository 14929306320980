import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as Styled from "./Loader.styles";
import { Spin } from "antd";
import styled from "styled-components";

const FullScreenWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Loader = ({
  style,
  className,
  height = "50px",
  fullScreen = false,
  ...props
}) => {
  const Wrapper = fullScreen ? FullScreenWrapper : Fragment;
  return (
    <Wrapper>
      <div
        className={className}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          height: height,
          ...style,
        }}
      >
        <Spin {...props} />
      </div>
    </Wrapper>
  );
};

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
