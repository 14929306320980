import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../user/userSlice";
// import { } from "./globalActions";

const initialState = {
  modals: {
    buyCredit: false,
  },
  loader: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    showBuyCreditModal(state) {
      state.modals.buyCredit = true;
    },
    hideBuyCreditModal(state) {
      state.modals.buyCredit = false;
    },
    toggleLoader(state, { payload }) {
      state.loader = payload;
    },
  },
  extraReducers: {
    [logout]: (state) => {
      state.modals = initialState.modals;
    },
  },
});

export const { showBuyCreditModal, hideBuyCreditModal, toggleLoader } =
  globalSlice.actions;

export default globalSlice.reducer;
