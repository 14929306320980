import React from "react";
import { Routes, Route } from "react-router-dom";
import Join from "./components/Join";
import Landing from "./Landing";

const RouteApp = (props) => {
  return (
    <Routes>
      <Route index path="/" element={<Landing />} />
      <Route path="/join" element={<Join />} />
    </Routes>
  );
};

RouteApp.propTypes = {};

RouteApp.defaultProps = {};

export default RouteApp;
