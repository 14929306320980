import React, { Fragment } from "react";
import { Card } from "antd";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  CoverImage,
  Overlay,
  StyledCard,
  TagWrapper,
  TitlePlaceholder,
} from "../common/CardBase";
import Masonry from "react-responsive-masonry";
const { Meta } = Card;

const LikeWrapper = styled.div`
  display: inline-block;
  opacity: 0.8;
  vertical-align: middle;

  &:hover {
    opacity: 1;
  }
`;

const ItemCard = ({
  image,
  images,
  title = "",
  tag,
  loading,
  description,
  noTitle = false,
  size = "default",
  flexible,
  favorite = false,
  onFavorite,
  withFavorite = false,
  onClick = () => {},
  overlayActions = null,
  animate = false,
}) => {
  const overlay = overlayActions ? <Overlay>{overlayActions}</Overlay> : null;

  let coverComponent = (
    <CoverImage loading={!image} image={image} size={size}>
      {overlay}
    </CoverImage>
  );
  if (images?.length) {
    coverComponent = (
      <CoverImage size={size}>
        {overlay}
        <Masonry
          columnsCount={images.length > 1 ? 2 : 1}
          gutter="5px"
          style={{
            padding: "2px",
            lineHeight: "0",
            minHeight: "100%",
            alignItems: "center",
          }}
        >
          {images.map((img) => (
            <div>
              <img
                src={img}
                alt="Preview"
                style={{
                  width: "100%",
                  borderRadius: "0",
                }}
              />
            </div>
          ))}
        </Masonry>
      </CoverImage>
    );
  }

  return (
    <StyledCard
      hoverable
      flexible={flexible}
      size={size}
      cover={coverComponent}
      noTitle={noTitle}
      onClick={onClick}
      animate={animate}
    >
      {!noTitle && (
        <Meta
          title={
            <TitlePlaceholder>
              <span className="cardTitle">{title}</span>
              {withFavorite && (
                <LikeWrapper onClick={onFavorite}>
                  {favorite ? (
                    <HeartFilled
                      style={{ fontSize: "17px", color: "#dc194e" }}
                    />
                  ) : (
                    <HeartOutlined
                      style={{ fontSize: "17px", color: "rgba(0,0,0,0.7)" }}
                    />
                  )}
                </LikeWrapper>
              )}
            </TitlePlaceholder>
          }
          description={
            description ? (
              <TitlePlaceholder>
                <span className="cardDescription">{description}</span>
              </TitlePlaceholder>
            ) : null
          }
        />
      )}
      {tag && <TagWrapper>{tag}</TagWrapper>}
    </StyledCard>
  );
};
export default ItemCard;
