import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHistoryAction,
  getQueueAction,
} from "../features/modelOutput/modelOutputActions";
import {
  getModelCategoryList,
  getAllModelsAction,
  searchModel as searchModelAction,
  getModelDetail as getModelDetailAction,
  top4Models,
  getFavoriteAction,
} from "../features/models/modelsActions";
import { useErrorNotification } from "./common";

const useModelData = () => useSelector((state) => state.models);
const useModelOutputData = () => useSelector((state) => state.modelOutput);

export function useGetModelCategoryList() {
  const dispatch = useDispatch();
  const models = useModelData();
  const { error } = models.modelCategory;
  useEffect(() => {
    dispatch(getModelCategoryList());
  }, []);

  useErrorNotification(error);

  return models.modelCategory;
}

export function useGetAllModels() {
  const dispatch = useDispatch();
  const models = useModelData();

  const getAllModels = (page, type) => {
    dispatch(getAllModelsAction({ page, type }));
  };

  useErrorNotification(models?.allModels?.error);

  return { ...models.allModels, getAllModels };
}

export function useGetTop4Model() {
  const dispatch = useDispatch();
  const models = useModelData();
  const { error } = models.top4Models;
  useEffect(() => {
    dispatch(top4Models());
  }, []);

  useErrorNotification(error);

  return models.top4Models;
}

export function useSearchModel() {
  const dispatch = useDispatch();
  const models = useModelData();

  const searchModel = (data) => {
    dispatch(searchModelAction(data));
  };
  useErrorNotification(models?.modelSearchResult?.error);
  return {
    searchModel,
    data: models.modelSearchResult,
  };
}

export function useGetFavoriteModels() {
  const dispatch = useDispatch();
  const models = useModelData();

  const getFavoriteModels = (page) => {
    dispatch(getFavoriteAction(page));
  };
  return {
    getFavoriteModels,
    data: models.favorite,
  };
}

export function useGetModelDetail() {
  const dispatch = useDispatch();
  const models = useModelData();

  const getModelDetail = (id) => {
    dispatch(getModelDetailAction(id));
  };
  return {
    getModelDetail,
    data: models.modelDetail,
  };
}

export function useGetHistory() {
  const dispatch = useDispatch();
  const modelOutput = useModelOutputData();

  const getHistory = (page) => {
    dispatch(getHistoryAction(page));
  };
  return {
    getHistory,
    data: modelOutput.history,
  };
}

export function useGetQueue() {
  const dispatch = useDispatch();
  const modelOutput = useModelOutputData();

  const getQueue = (page) => {
    dispatch(getQueueAction(page));
  };
  return {
    getQueue,
    data: modelOutput.queue,
  };
}
