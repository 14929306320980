import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Styled from "./Login.styles";
import styled from "styled-components";
import LoginForm from "../../components/Login";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../features/user/userActions";
import { clearError, clearTempData } from "../../features/user/userSlice";

const Wrapper = styled.div(() => ({
  display: "flex",
  minHeight: "calc(100vh - 140px)",
  justifyContent: "center",
  alignItems: "center",
}));

const Login = () => {
  const { userInfo, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    dispatch(userLogin(data));
  };

  const onCloseError = () => {
    dispatch(clearError());
  };

  useEffect(() => {
    return () => {
      dispatch(clearTempData());
    };
  }, []);

  return (
    <Wrapper>
      <LoginForm
        onSubmit={submitForm}
        error={error}
        onCloseError={onCloseError}
      />
    </Wrapper>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
