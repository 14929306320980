import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    var(--grid-items-count),
    var(--grid-item-width)
  );
  justify-content: center;
  row-gap: 0px;
`;

export const Grid = ({ children, itemWidth = 0, itemWindowWidthMap }) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [finalItemWidth, setFinalItemWidth] = useState(itemWidth);
  const ref = useRef(null);

  useEffect(() => {
    function updateItemsCount() {
      if (ref.current) {
        let newItemWidth = itemWidth;
        if (itemWindowWidthMap) {
          const windowWidthArr = Object.keys(itemWindowWidthMap).sort(
            (a, b) => b - a
          );
          const windowWidthKey = windowWidthArr.find(
            (width) => window.innerWidth > width
          );
          newItemWidth = itemWindowWidthMap[windowWidthKey];
        }
        const newItemsCount = Math.floor(
          (ref.current.clientWidth - 30) / newItemWidth
        );
        setItemsCount(newItemsCount || 1);
        setFinalItemWidth(newItemWidth);
      }
    }

    updateItemsCount();
    window.addEventListener("resize", updateItemsCount);
    return () => window.removeEventListener("resize", updateItemsCount);
  }, [itemWidth]);

  return (
    <GridWrapper
      ref={ref}
      style={{
        "--grid-item-width": `${finalItemWidth}px`,
        "--grid-items-count": `${itemsCount}`,
      }}
    >
      {itemsCount ? children : null}
    </GridWrapper>
  );
};

export const CardWrapper = styled.div`
  padding: 5px;
  flex: 1;
`;
