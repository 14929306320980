import React, { useState, useEffect } from "react";
import SearchInput from "../../components/Search";
import { useGetAllModels, useSearchModel } from "../../hooks/modelHooks";
import CategoryGrid from "../../components/CategoryGrid";
import styled from "styled-components";
import { Button, Divider, Result, Tabs, Typography } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const { Title } = Typography;

const QUERY_PARAMS = {
  PAGE: "page",
  SEARCH: "search",
  TAB: "type",
};

const TAB_KEYS = {
  PUBLIC: "public",
  PRIVATE: "private",
};

const SearchInputWrapper = styled.div`
  margin: 0px 0 20px;
  border-radius: 5px;
`;

const Search = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentSearchText, setCurrentSearchtext] = useState(
    searchParams.get(QUERY_PARAMS.SEARCH) || ""
  );
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get(QUERY_PARAMS.PAGE) || 1)
  );

  const [currentTab, setCurrentTab] = useState(
    searchParams.get(QUERY_PARAMS.TAB) || TAB_KEYS.PUBLIC
  );

  const {
    data: allModelData,
    loading: allModelsLoading,
    getAllModels,
  } = useGetAllModels();

  const { searchModel: onSearch, data: searchResponse } = useSearchModel();
  const { data: searchResult, loading: seachLoading } = searchResponse;
  const isSearchResult = currentSearchText?.length;

  const gridData =
    isSearchResult && !seachLoading ? searchResult : allModelData;
  const gridItems = gridData?.results || [];

  const updateSearchParams = ({ searchText, page, tab }) => {
    const params = {
      [QUERY_PARAMS.PAGE]: page,
    };
    if (searchText) {
      params[QUERY_PARAMS.SEARCH] = searchText;
    }
    if (tab) {
      params[QUERY_PARAMS.TAB] = tab;
    }
    setSearchParams(`?${new URLSearchParams(params)}`);
  };

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    const searchText = currentParams[QUERY_PARAMS.SEARCH] || "";
    const page = Number(searchParams.get(QUERY_PARAMS.PAGE) || 1);
    const tab = currentParams[QUERY_PARAMS.TAB] || TAB_KEYS.PUBLIC;
    if (currentParams[QUERY_PARAMS.SEARCH]) {
      onSearch({
        searchText,
        page,
      });
    } else {
      getAllModels(page, tab);
    }
    setCurrentSearchtext(searchText);
    setCurrentPage(page);
    setCurrentTab(tab);
  }, [searchParams]);

  const handleSearch = (data) => {
    const { searchText } = data;
    updateSearchParams({
      searchText,
      page: 1,
    });
  };

  const isLoading = allModelsLoading || seachLoading;

  const onPageChange = (page, pageSize) => {
    updateSearchParams({
      searchText: currentSearchText,
      page,
      tab: currentTab,
    });
  };

  const onTabChange = (key) => {
    updateSearchParams({
      searchText: currentSearchText,
      page: 1,
      tab: key,
    });
  };

  let result = null;
  if (isLoading) {
    result = <Loader height="100%" />;
  } else if (gridItems?.length) {
    result = (
      <CategoryGrid
        items={gridItems}
        withPagination
        paginationProps={{
          current: currentPage,
          pageSize: gridData.page_size,
          total: gridData.count,
          onChange: onPageChange,
        }}
      />
    );
  } else {
    result = <Result status="404" title="Oops!" subTitle="No results found." />;
  }

  const items = [
    {
      key: TAB_KEYS.PUBLIC,
      label: `Public Generators`,
      children: result,
    },
    {
      key: TAB_KEYS.PRIVATE,
      label: `Private Generators`,
      children: result,
    },
  ];

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Title level={3}>Generators</Title>
      </Divider>
      <SearchInputWrapper>
        <SearchInput onSearch={handleSearch} searchText={currentSearchText} />
      </SearchInputWrapper>
      {isSearchResult ? (
        <>
          <Divider orientation="left" orientationMargin="0">
            <Title level={5}>Results for {currentSearchText}</Title>
          </Divider>
          {result}
        </>
      ) : (
        <Tabs
          tabBarExtraContent={
            <Link to="/create-generator">
              <Button type="primary" ghost>
                Create New
              </Button>
            </Link>
          }
          defaultActiveKey={currentTab}
          activeKey={currentTab}
          destroyInactiveTabPane
          items={items}
          onChange={onTabChange}
        />
      )}
    </>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
