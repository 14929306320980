import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./ResendEmailButton.styles";
import { Button, notification } from "antd";
import { sendVerificationEmail } from "../../services/user";

const ResendEmailButton = ({ currentUser, intialResendDisable = false }) => {
  const [time, setTime] = useState(60);
  const [timeActive, setTimeActive] = useState(intialResendDisable);

  useEffect(() => {
    let interval = null;
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive]);

  const resendEmailVerification = () => {
    sendVerificationEmail({ email: currentUser?.email })
      .then(() => {
        setTimeActive(true);
      })
      .catch((err) => {
        console.error(err.message);
        notification.error({
          message: "Something went wrong. Please try after sometime.",
        });
      });
  };

  return (
    <Button onClick={resendEmailVerification} disabled={timeActive} type="link">
      Resend Email {timeActive && `in ${time} sec`}
    </Button>
  );
};

ResendEmailButton.propTypes = {};

ResendEmailButton.defaultProps = {};

export default ResendEmailButton;
