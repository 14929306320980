import React from "react";
import styled from "styled-components";
import { Collapse } from "antd";

export const AdvanceSettingsCollapse = styled(Collapse)`
  margin-bottom: 16px;

  .ant-collapse-header {
    font-weight: 600;
  }
`;
