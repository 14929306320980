import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./CreditPlanCard.styles";
import { Button, Card, Space, Statistic, Typography, theme, Badge } from "antd";
import styled from "styled-components";

const { Title, Text } = Typography;
const { useToken } = theme;

const StyledCard = styled(Card)`
  width: 240px;
  text-align: center;
  transition: all 0.3s ease-in-out;
`;

const CreditPlanCard = ({
  title = "",
  creditCount,
  price,
  onBuy,
  badge = null,
}) => {
  const { token } = useToken();
  const [hover, setHover] = useState(false);

  const card = (
    <StyledCard
      bordered={false}
      hoverable
      style={hover ? { backgroundColor: token.colorPrimary } : {}}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onBuy}
    >
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <div>
          <Title level={3} style={{ margin: "10px 0" }}>
            {title}
          </Title>
          <Text italic>{creditCount} credits</Text>
        </div>
        <Statistic value={price} valueStyle={{ color: "" }} />
        <Button block type={hover ? "default" : "primary"} size="large">
          Buy Now
        </Button>
      </Space>
    </StyledCard>
  );

  if (!badge) {
    return card;
  }
  return <Badge.Ribbon text={badge}>{card}</Badge.Ribbon>;
};

CreditPlanCard.propTypes = {};

CreditPlanCard.defaultProps = {};

export default CreditPlanCard;
