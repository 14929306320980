import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import LazyLoad from "react-lazy-load";
import Card from "../../Card";
import Carousel from "../../Carousel";
import Section from "./Section";
import GameImage1 from "../../../images/landing-page/assets/game/1.webp";
import GameImage2 from "../../../images/landing-page/assets/game/2.webp";
import GameImage3 from "../../../images/landing-page/assets/game/3.webp";
import GameImage4 from "../../../images/landing-page/assets/game/4.webp";
import GameImage5 from "../../../images/landing-page/assets/game/5.webp";
import GameImage6 from "../../../images/landing-page/assets/game/6.webp";
import GameImage7 from "../../../images/landing-page/assets/game/7.webp";
import GameImage8 from "../../../images/landing-page/assets/game/8.webp";
import GameImage9 from "../../../images/landing-page/assets/game/9.webp";
import GameImage10 from "../../../images/landing-page/assets/game/10.webp";
import GameImage11 from "../../../images/landing-page/assets/game/11.webp";
import GameImage12 from "../../../images/landing-page/assets/game/12.webp";
import GameImage13 from "../../../images/landing-page/assets/game/13.webp";
import GameImage14 from "../../../images/landing-page/assets/game/14.webp";

import Human1 from "../../../images/landing-page/assets/virtual-humans/1.webp";
import Human2 from "../../../images/landing-page/assets/virtual-humans/2.webp";
import Human3 from "../../../images/landing-page/assets/virtual-humans/3.webp";
import Human4 from "../../../images/landing-page/assets/virtual-humans/4.webp";
import Human5 from "../../../images/landing-page/assets/virtual-humans/5.webp";
import Human6 from "../../../images/landing-page/assets/virtual-humans/6.webp";
import Human7 from "../../../images/landing-page/assets/virtual-humans/7.webp";
import Human8 from "../../../images/landing-page/assets/virtual-humans/8.webp";
import Human9 from "../../../images/landing-page/assets/virtual-humans/9.webp";
import Human10 from "../../../images/landing-page/assets/virtual-humans/10.webp";
import Human11 from "../../../images/landing-page/assets/virtual-humans/11.webp";
import Human12 from "../../../images/landing-page/assets/virtual-humans/12.webp";
import Human13 from "../../../images/landing-page/assets/virtual-humans/13.webp";
import Human14 from "../../../images/landing-page/assets/virtual-humans/14.webp";
import Human15 from "../../../images/landing-page/assets/virtual-humans/15.webp";

import Web1 from "../../../images/landing-page/assets/web/1.webp";
import Web2 from "../../../images/landing-page/assets/web/2.webp";
import Web3 from "../../../images/landing-page/assets/web/3.webp";
import Web4 from "../../../images/landing-page/assets/web/4.webp";
import Web5 from "../../../images/landing-page/assets/web/5.webp";
import Web6 from "../../../images/landing-page/assets/web/6.webp";

import Product1 from "../../../images/landing-page/assets/product-photos/1.webp";
import Product2 from "../../../images/landing-page/assets/product-photos/2.webp";
import Product3 from "../../../images/landing-page/assets/product-photos/3.webp";
import Product4 from "../../../images/landing-page/assets/product-photos/4.webp";
import Product5 from "../../../images/landing-page/assets/product-photos/5.webp";
import Product6 from "../../../images/landing-page/assets/product-photos/6.webp";
import Product7 from "../../../images/landing-page/assets/product-photos/7.webp";
import Product8 from "../../../images/landing-page/assets/product-photos/8.webp";
import Product9 from "../../../images/landing-page/assets/product-photos/9.webp";
// import Product10 from "../../../images/landing-page/assets/product-photos/10.webp";
import Product11 from "../../../images/landing-page/assets/product-photos/11.webp";
import Product12 from "../../../images/landing-page/assets/product-photos/17.webp";
import Product13 from "../../../images/landing-page/assets/product-photos/13.webp";
import Product14 from "../../../images/landing-page/assets/product-photos/14.webp";
import Product15 from "../../../images/landing-page/assets/product-photos/15.webp";
import Product16 from "../../../images/landing-page/assets/product-photos/16.webp";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Grid } from "../../common/GridWrapper";

const TabContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tab = styled.div`
  ${({ selected }) => css`
    border-radius: 50px;
    font-size: 1rem;
    border: 2px solid #000;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    ${selected &&
    css`
      border: 2px solid #6f52f0;
      background-color: #6f52f0;
      color: #fff;
    `}
  `}
`;

const scaleAnimation = keyframes`
  0% {
    transform: scale(0);
}
100% {
  transform: scale(1);
}
`;

const CarouselWrapper = styled.div`
  margin: 30px 0;
  content-visibility: auto;

  img {
    transform: scale(0);
    animation: ${scaleAnimation} 450ms cubic-bezier(0.65, 0.05, 0.36, 1)
      forwards;
  }
`;

const tabsTypes = {
  PRODUCT: "Product photos",
  VIRTUAL_HUMANS: "Virtual humans",
  // GAME: "Game assets",
  // WEB: "Web assets",
};

const tabImages = {
  [tabsTypes.GAME]: [
    GameImage14,
    GameImage2,
    GameImage7,
    GameImage3,
    GameImage12,
    GameImage8,
    GameImage10,
    GameImage11,
    GameImage9,
    GameImage5,
    GameImage6,
    GameImage4,
    GameImage1,
    GameImage13,
  ],
  [tabsTypes.VIRTUAL_HUMANS]: [
    Human1,
    Human2,
    Human3,
    Human4,
    Human5,
    Human6,
    Human7,
    Human8,
    Human9,
    Human10,
    Human11,
    Human12,
    Human13,
    Human14,
    Human15,
  ],
  [tabsTypes.WEB]: [Web5, Web1, Web6, Web2, Web3, Web4],
  [tabsTypes.PRODUCT]: [
    Product1,
    Product2,
    Product3,
    Product4,
    Product5,
    Product6,
    Product7,
    Product8,
    Product9,
    // Product10,
    Product11,
    Product12,
    Product13,
    Product14,
    Product15,
    Product16,
  ],
};

function Examples() {
  const [selected, setSelected] = useState(tabsTypes.PRODUCT);
  return (
    <Section
      title="Explore"
      id="explore"
      // style={{ maxWidth: "1500px", margin: "auto" }}
    >
      <div>
        <TabContainer>
          {Object.keys(tabsTypes).map((tabKey) => (
            <Tab
              selected={selected === tabsTypes[tabKey]}
              key={tabKey}
              onClick={() => setSelected(tabsTypes[tabKey])}
            >
              {tabsTypes[tabKey]}
            </Tab>
          ))}
        </TabContainer>
        <CarouselWrapper>
          <LazyLoad offset={300}>
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                400: 2,
                700: 3,
                900: 4,
                1200: 5,
              }}
            >
              <Masonry gutter="5px">
                {tabImages[selected].map((img, index) => {
                  return (
                    <img
                      src={img}
                      key={`image-${selected}-${index}`}
                      alt="Product exmaple"
                    />
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
            {/* <Grid itemWindowWidthMap={{ 0: 150, 600: 240 }}>
              {tabImages[selected].map((img, index) => {
                return <Card animate noTitle key={index} image={img} />;
              })}
            </Grid> */}
          </LazyLoad>
        </CarouselWrapper>
      </div>
    </Section>
  );
}

export default Examples;
