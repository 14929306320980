import { App } from "antd";
// import throttle from "lodash/throttle";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { ERROR_NOTIFICATION_DURATION } from "../constants";

let currentErrorCount = new Map();

function registerErrorType(error) {
  const errorCount = currentErrorCount.get(error);
  if (!errorCount) {
    currentErrorCount.set(error, 1);
    setTimeout(() => {
      currentErrorCount.delete(error);
    }, ERROR_NOTIFICATION_DURATION * 1000);
  } else {
    currentErrorCount.set(error, errorCount + 1);
  }
  return currentErrorCount.get(error);
}

export function useErrorNotification(error) {
  const { notification } = App.useApp();
  useEffect(() => {
    if (error) {
      const errorCount = registerErrorType(error);
      if (errorCount > 1) {
        return;
      }
      notification.error({
        message: error,
        duration: ERROR_NOTIFICATION_DURATION,
      });
    }
  }, [error]);
}

export const useIsOverflow = (ref, fixedWidth) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow =
        current.scrollHeight > (fixedWidth || current.clientHeight);

      setIsOverflow(hasOverflow);
    };

    if (current) {
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [ref, fixedWidth]);

  return isOverflow;
};

export function usePolling(pollingFn, interval, ...args) {
  const [data, setData] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  // to handle if pollingfn is not yet resolved and stop is called
  const isPollingRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    let _stopped = false;
    const poll = async () => {
      const result = await pollingFn(...args);
      if (result) {
        setData(result);
      }
      if (!_stopped) {
        timeoutId = setTimeout(poll, interval);
      }
    };
    if (isPolling) {
      poll();
    }
    return () => {
      _stopped = true;
      clearTimeout(timeoutId);
    };
  }, [interval, isPolling, ...args]);

  const start = useCallback(() => {
    isPollingRef.current = true;
    setIsPolling(true);
  }, []);

  const stop = useCallback(() => {
    isPollingRef.current = false;
    setIsPolling(false);
  }, []);

  return { data, start, stop, isPolling: isPollingRef.current };
}

export default function useForceUpdate() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return forceUpdate;
}
