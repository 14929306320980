import React from "react";
import styled from "styled-components";
import { Button, Col, Form, Input, Row, Typography, App } from "antd";
import { sendPasswordResetEmail } from "../../features/user/userActions";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const PageWrapper = styled.div(() => ({
  display: "flex",
  minHeight: "calc(100vh - 140px)",
  justifyContent: "center",
  alignItems: "center",
}));

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  box-shadow: 0 15px 16.83px 0.17px rgb(0 0 0 / 5%);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  padding-bottom: 10px;
`;

const ForgotPasswordContainer = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <FormWrapper>
        <Row
          align="middle"
          justify="space-around"
          style={{ flex: 1, paddingBottom: "50px" }}
        >
          <Col span={18}>
            <ContentWrapper>
              <Title level={2}>Forgot Password</Title>
            </ContentWrapper>
            <Form
              name="userform"
              autoComplete="off"
              onFinish={({ email }) =>
                sendPasswordResetEmail(email).then(() => {
                  message.success(
                    `Recovery link sent successfully to ${email}`
                  );
                  navigate("/login");
                })
              }
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
                style={{ marginBottom: 16 }}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>

              <Form.Item>
                <Button size="large" block type="primary" htmlType="submit">
                  Get Recovery Link
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </FormWrapper>
    </PageWrapper>
  );
};

ForgotPasswordContainer.propTypes = {};

ForgotPasswordContainer.defaultProps = {};

export default ForgotPasswordContainer;
