import React from "react";
import { Col, Row, Slider as SliderBase, InputNumber } from "antd";

function Slider({ value, onChange, ...props }) {
  return (
    <Row gutter={16}>
      <Col span={20}>
        <SliderBase
          onChange={onChange}
          value={typeof value === "number" ? value : 0}
          {...props}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          style={{ width: "100%" }}
          min={props.min}
          max={props.max}
          value={value}
          onChange={onChange}
          step={props.step}
        />
      </Col>
    </Row>
  );
}

export default Slider;
