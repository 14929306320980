import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./ImageCarousel.styles";
import styled from "styled-components";
import { Button, Carousel } from "antd";
import {
  RightOutlined,
  LeftOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Card from "../Card";

const CarouselWrapper = styled.div`
  margin: auto;
  width: 500px;
  min-height: 300px;

  .download-container {
    position: relative;
  }

  .download-image {
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
    z-index: 20;
    svg {
      filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.9));
    }
  }

  .slick-thumb {
    bottom: -80px;

    li {
      width: auto;
      height: auto;
      border: 2px solid transparent;
      border-radius: 6px;
      opacity: 0.8;
    }

    li.slick-active {
      width: auto;
      height: auto;
      border: 2px solid #1668dc;
      opacity: 1;
    }
  }

  .ant-carousel {
    position: relative;
    ::before,
    ::after {
      position: absolute;
      height: 100%;
      width: 60px;
      top: 0px;
      opacity: 0.7;
      content: "";
      z-index: 5;
    }
    ::before {
      left: 0;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0)
      );
    }
    ::after {
      right: 0;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
      );
    }
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-prev:focus {
    width: 35px;
    height: 35px;
    font-size: 35px;
    left: 10px;
    z-index: 10;
    color: white;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-next:focus {
    width: 35px;
    height: 35px;
    font-size: 35px;
    right: 10px;
    z-index: 10;
    color: white;
  }
`;

const PreviewSlide = styled.div`
  img {
    margin: auto;
    max-width: 100%;
    min-height: 300px;
  }
`;

const ImageCarousel = ({
  images = [],
  slideIndex = 0,
  onDownload,
  afterChange: afterChangeProp,
  ...otherProps
}) => {
  const [selectedIndex, setSelectedIndex] = useState(slideIndex);
  const afterChange = (index) => {
    setSelectedIndex(index);
    afterChangeProp?.(index);
  };
  return (
    <CarouselWrapper>
      <div className="download-container">
        {onDownload ? (
          <Button
            title="Download Image"
            className="download-image"
            type="text"
            icon={
              <DownloadOutlined style={{ fontSize: "25px", color: "#fff" }} />
            }
            size="large"
            onClick={() => onDownload(selectedIndex)}
          />
        ) : null}
        <Carousel
          arrows
          style={{ width: "500px", marginBottom: "80px" }}
          initialSlide={slideIndex}
          dots={{
            className: "slick-dots slick-thumb",
          }}
          nextArrow={<RightOutlined />}
          prevArrow={<LeftOutlined />}
          customPaging={(i) => (
            <Card image={images[i]} size="thumbnail" noTitle />
          )}
          afterChange={afterChange}
          {...otherProps}
        >
          {images.map((image) => (
            <PreviewSlide key={image}>
              <img src={image} />
            </PreviewSlide>
          ))}
        </Carousel>
      </div>
    </CarouselWrapper>
  );
};

ImageCarousel.propTypes = {};

ImageCarousel.defaultProps = {};

export default ImageCarousel;
