import React from "react";
import styled, { css, keyframes } from "styled-components";
import { GENERATION_STATUS, statusColor } from "../../constants";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

export const BlinkAnimation = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  opacity: 1;
}
`;

const StatusWrapper = styled.span`
  ${({ status }) => css`
    color: ${statusColor[status]};
    font-size: inherit;
    text-transform: inherit;
    display: inline-block;
    padding: 0 2px;
    vertical-align: middle;

    &:before {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: ${statusColor[status]};
      content: "";
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      box-shadow: 0 0 5px ${statusColor[status]};
      ${status === GENERATION_STATUS.PENDING &&
      css`
        animation: ${BlinkAnimation} 1s linear infinite;
      `}
    }
  `}
`;

const statusIcons = {
  [GENERATION_STATUS.DONE]: CheckCircleFilled,
  [GENERATION_STATUS.PENDING]: LoadingOutlined,
  [GENERATION_STATUS.FAILURE]: CloseCircleFilled,
};

const Status = ({ status, withIcon = true, size = 13 }) => {
  if (withIcon) {
    const Icon = statusIcons[status];
    return <Icon size={size} style={{ color: statusColor[status] }} />;
  }
  return <StatusWrapper status={status}>{status}</StatusWrapper>;
};

export default Status;
