import { Typography, theme, Grid } from "antd";
import React from "react";
import styled from "styled-components";
import { GradientBackground } from "./styles";

const { useToken } = theme;
const { useBreakpoint } = Grid;

const { Title } = Typography;

const SectionWrapper = styled.section`
  margin: 50px 0;
`;

const SectionTitle = styled(Title)`
  /* text-align: center; */
  margin-top: 50px;
  position: relative;
  color: #000 !important;
  font-size: 50px !important;
  text-align: center;

  &:after {
    content: "";
    width: 60px;
    height: 4px;
    ${GradientBackground}
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
  }
`;

const Content = styled.div`
  padding: 40px 0;
`;

const Section = ({ title, children, style, id }) => {
  const { token } = useToken();
  const breakpoint = useBreakpoint();
  return (
    <SectionWrapper style={style} id={id}>
      {title ? (
        <SectionTitle level={breakpoint.md ? 1 : 2} color={token.colorPrimary}>
          {title}
        </SectionTitle>
      ) : null}
      <Content>{children}</Content>
    </SectionWrapper>
  );
};

export default Section;
