import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config";
import {
  downloadImageAPI,
  fetchUsageStatus,
  getHistoryAPI,
} from "../../services/model";
import { downloadImage } from "../../utils/utils";
import { getAvailableCreditsAction } from "../credits/creditsActions";

export const processModelList = (list) => {
  return list.reduce((acc, item) => {
    const imagesArr = item.images.map(
      (imageObj) => `${API_BASE_URL}/${imageObj.image}`
    );
    return [
      ...acc,
      {
        ...item,
        imagesArr,
      },
    ];
  }, []);
};

export const fairUsageStatusAction = createAsyncThunk(
  "modelOutput/fairUsageStatusAction",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await fetchUsageStatus();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const downloadImageAction = createAsyncThunk(
  "modelOutput/downloadImageAction",
  async (downloadImageObj, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await downloadImageAPI(downloadImageObj);
      downloadImage(`${API_BASE_URL}/${data}`);
      dispatch(getAvailableCreditsAction());
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getHistoryAction = createAsyncThunk(
  "modelOutput/getHistoryAction",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await getHistoryAPI(page);
      return {
        ...data,
        results: processModelList(data.results),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getQueueAction = createAsyncThunk(
  "modelOutput/getQueueAction",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await getHistoryAPI(page);
      return {
        ...data,
        results: processModelList(data.results),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
