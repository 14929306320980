import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./GlobalContainer.styles";
import { App, Modal } from "antd";
import BuyCreditsContainer from "../BuyCreditsContainer";
import { useDispatch, useSelector } from "react-redux";
import { hideBuyCreditModal } from "../../features/global/globalSlice";
import Debug from "../../components/Debug/Debug";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import FloatingStatusQueueContainer from "../../components/FloatingStatusQueue/FloatingStatusQueueContainer";
import Loader from "../../components/Loader/Loader";

const STATUS_QUEUE_HIDE_ROUTES = ["/create-generator"];

const GlobalContainer = (props) => {
  const { modals, loader } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [showDebug, setShowDebug] = useState(false);
  let [searchParams] = useSearchParams();
  const { message } = App.useApp();
  useEffect(() => {
    const isDebug = searchParams.get("debug");
    if (isDebug === "true") {
      setShowDebug(true);
    }

    const showOnline = () => {
      message.success({
        content: "Back Online",
      });
    };

    const showOffline = () => {
      message.error({
        content: "No Internet Connection",
      });
    };

    window.addEventListener("online", showOnline);
    window.addEventListener("offline", showOffline);

    return () => {
      window.removeEventListener("online", showOnline);
      window.removeEventListener("offline", showOffline);
    };
  }, []);
  const location = useLocation();

  const hideStatusQueue = STATUS_QUEUE_HIDE_ROUTES.includes(location.pathname);

  return (
    <>
      <Modal
        title=""
        centered
        width={1000}
        open={modals.buyCredit}
        onCancel={() => {
          dispatch(hideBuyCreditModal());
        }}
        footer={[]}
      >
        <BuyCreditsContainer />
      </Modal>
      {!hideStatusQueue && <FloatingStatusQueueContainer />}
      {showDebug ? <Debug /> : null}
      {loader && <Loader fullScreen />}
    </>
  );
};

GlobalContainer.propTypes = {};

GlobalContainer.defaultProps = {};

export default GlobalContainer;
