import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Home.styles";
import Banner from "../../components/Banner/Banner";
import Category from "../../components/Category/Category";
import {
  useGetModelCategoryList,
  useGetTop4Model,
} from "../../hooks/modelHooks";

const Home = (props) => {
  const { data, loading } = useGetModelCategoryList();
  const { data: top4Models = [], loading: loadingTop4Models } =
    useGetTop4Model();
  const categoryModels = data || [];

  return (
    <>
      <div style={{ padding: "20px 0", maxWidth: "1200px", margin: "auto" }}>
        <Banner top4Models={top4Models} loading={loadingTop4Models} />
      </div>
      {loading ? (
        <>
          <Category />
          <Category />
          <Category />
          <Category />
          <Category />
        </>
      ) : (
        categoryModels?.map(({ category, aimodels }) => (
          <Category key={category} title={category} items={aimodels} />
        ))
      )}
    </>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
