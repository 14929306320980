import { Typography, Grid, Divider } from "antd";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import Section from "./Section";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import poseInput from "../../../images/landing-page/before-after/pose-before.jpg";
import poseOutput from "../../../images/landing-page/before-after/pose-after.png";
import productInput from "../../../images/landing-page/before-after/product-before.jpg";
import productOutput from "../../../images/landing-page/before-after/product-after.jpg";
import assetsImage from "../../../images/landing-page/features-section.png";
import { useParallax } from "react-scroll-parallax";

const { useBreakpoint } = Grid;

const FeatureList = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`;

const blinkAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: rgba(111, 82, 240, 1) 0px 0px 0px 0px;
}


70% {
    transform: scale(1.1);
    box-shadow: rgba(111, 82, 240, 0) 0px 0px 0px 10px;
}

100% {
    transform: scale(0.95);
    box-shadow: rgba(111, 82, 240, 0) 0px 0px 0px 0px;
}
`;

const { Title } = Typography;

const FeatureWrapper = styled.div`
  ${({ reverse, noShadow }) => css`
    display: grid;
    grid-template-columns: ${reverse ? "1fr 393px" : "393px 1fr"};
    gap: 1.5rem;

    .feature-content {
      padding: ${reverse ? "0 3rem 0 0" : "0 0 0 3rem"};
      order: ${reverse ? "-1" : "auto"};
      display: flex;
      align-items: center;
    }

    .feature-media {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: ${noShadow
        ? "none"
        : `rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.12) 0px 16px 32.77px 0px`};
      & > * {
        display: block;
        max-width: 100%;
        margin: auto;
      }
      .__rcs-handle-button {
        background-color: rgba(111, 82, 240, 0.7);
        animation: 2s ease 0s infinite normal none running ${blinkAnimation};
      }
      :hover {
        .__rcs-handle-button {
          background-color: transparent;
          animation: none;
        }
      }
      .__rcs-handle-root {
        position: relative;
        :before,
        :after {
          bottom: 10px;
          color: #000;
          padding: 0 5px;
          border-radius: 2px;
          font-size: 12px;
          background-color: #fff;
        }
        :before {
          content: "Original";
          position: absolute;
          left: 10px;
          transform: translateX(-100%);
        }
        :after {
          content: "Generated";
          position: absolute;
          right: 10px;
          transform: translateX(100%);
        }
        @media screen and (max-width: 540px) {
          :before,
          :after {
            font-size: 10px;
          }
        }
      }
    }

    .feature-title {
      color: #000;
      margin-top: 10px !important;
    }

    .feature-category {
      color: rgba(111, 82, 240, 1);
      text-transform: uppercase;
      font-size: 16px !important;
      margin: 0 !important;
    }

    @media screen and (max-width: 1072px) {
      .feature-content {
        padding: ${reverse ? "0 2rem 0 0" : "0 0 0 2rem"};
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;

      .feature-content {
        order: 1;
        padding: 0;
        margin: auto;
      }

      .feature-media {
        margin: 0 40px;
      }
    }
  `}
`;

const Feature = ({
  title,
  description,
  media,
  reverse,
  noShadow,
  category,
}) => {
  const featureMedia = media || (
    <video autoPlay disablePictureInPicture loop muted playsInline>
      <source
        src="https://static.photoroom.com/video/home/background-remover.mp4"
        type="video/mp4"
      />
    </video>
  );
  const breakpoint = useBreakpoint();
  const parallax = useParallax({
    speed: breakpoint.md ? 5 : 0,
  });
  return (
    <FeatureWrapper reverse={reverse} noShadow={noShadow}>
      <div className="feature-media" ref={parallax.ref}>
        {featureMedia}
      </div>
      {/* <div className="feature-media-small">{featureMedia}</div> */}
      <div className="feature-content">
        <div>
          <Title level={5} className="feature-category">
            {category}
          </Title>
          <Title level={breakpoint.md ? 1 : 2} className="feature-title">
            {title}
          </Title>
          <Title
            level={breakpoint.md ? 4 : 5}
            style={{ fontWeight: "400" }}
            type="secondary"
            className="feature-description"
          >
            {description}
          </Title>
        </div>
      </div>
    </FeatureWrapper>
  );
};

function Features() {
  const breakpoint = useBreakpoint();
  return (
    <Section title="Features" id="features">
      <FeatureList>
        <Feature
          category="Product photoshoot"
          media={
            <ReactCompareSlider
              boundsPadding={10}
              itemOne={<ReactCompareSliderImage src={productInput} />}
              itemTwo={<ReactCompareSliderImage src={productOutput} />}
            />
          }
          title="DIY Product Photoshoots"
          description={
            <>
              Revolutionize your product photography with our DIY solution.{" "}
              <strong>Create stunning, studio-quality images in seconds</strong>{" "}
              with our cutting-edge AI technology.
              {/* Elevate your e-commerce game with visually appealing product
              imagery that will leave a lasting impression on your customers. */}
            </>
          }
        />
        <Feature
          category="virtual human images"
          reverse
          media={
            <ReactCompareSlider
              boundsPadding={20}
              itemOne={<ReactCompareSliderImage src={poseInput} />}
              itemTwo={<ReactCompareSliderImage src={poseOutput} />}
            />
          }
          title="Generate humans from pose references"
          description={
            <>
              Transform your ideas into reality by generating highly realistic
              human images. Choose from a diverse collection of over{" "}
              <strong>
                100+ predefined pose templates or easily upload your own
                reference images.
              </strong>{" "}
              {/* The outputs are of the highest quality, perfect for gaming,
              e-commerce, or social media campaigns. */}
            </>
          }
        />
        {!breakpoint.md ? <Divider /> : null}
        {/* <Feature
          category="Game & Web Assets"
          title="Generate high quality assets"
          description={
            <>
              <strong>
                Create high-quality game and web assets, social media campaigns,
              </strong>{" "}
              and other visuals in a matter of seconds. Elevate your content to
              the next level with stunning, studio-quality images.
              {/* ry that is sure to capture the attention of
              your audience.}
            </>
            }
          media={<img src={assetsImage} />}
          reverse
        /> */}
        {/* {!breakpoint.md ? <Divider /> : null} */}
      </FeatureList>
    </Section>
  );
}

export default Features;
