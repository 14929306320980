import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./StatusQueue.styles";
import { App, Space, Table, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import { API_BASE_URL } from "../../config";
import ImagePreviewContainer from "../ImagePreview/ImagePreviewContainer";
import { formatStatusQueueData } from "../../utils/utils";
import { Link, useLocation } from "react-router-dom";
import { EyeOutlined, CopyOutlined } from "@ant-design/icons";
import Status from "../common/Status";
import styled from "styled-components";

const ExpandedRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const StatusQueue = ({ data = [] }) => {
  const formattedData = formatStatusQueueData(data);
  const location = useLocation();
  const { message } = App.useApp();

  const handleCopyPrompt = (prompt) => {
    copy(prompt);
    message.success("Prompt copied!!");
  };

  const columns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: "50px",
      render: (status) => <Status status={status} withIcon />,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      width: "130px",
      ellipsis: {
        showTitle: false,
      },
      render: (prompt) => (
        <Tooltip placement="topLeft" title={prompt}>
          {prompt}
        </Tooltip>
      ),
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      key: "prompt",
      width: "150px",
      ellipsis: {
        showTitle: false,
      },
      render: (prompt) => (
        <Tooltip placement="topLeft" title={prompt}>
          {prompt}
        </Tooltip>
      ),
    },
    {
      title: "Negative Prompt",
      dataIndex: "negative_prompt",
      width: "150px",
      ellipsis: {
        showTitle: false,
      },
      render: (negative_prompt) => (
        <Tooltip placement="topLeft" title={negative_prompt}>
          {negative_prompt}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            <Link
              to={`/preview/${record.id}`}
              state={{ backgroundLocation: location }}
            >
              <EyeOutlined />
            </Link>
          </Tooltip>
          <Tooltip title="Copy Prompt">
            <CopyOutlined
              style={{ cursor: "pointer" }}
              onClick={() => handleCopyPrompt(record.prompt)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={formattedData}
      scroll={{ y: 380 }}
      expandable={{
        expandedRowRender: (record) => (
          <ExpandedRow>
            {record.images.map((imgObj, index) => (
              <ImagePreviewContainer
                size="thumbnail"
                image={`${API_BASE_URL}/${imgObj.image}`}
                id={imgObj.id}
                promptId={imgObj.user_prompt}
                previewUrl={`/preview/${imgObj.user_prompt}?item=${index}`}
              />
            ))}
          </ExpandedRow>
        ),
        rowExpandable: (record) => record?.imagesArr?.length,
      }}
    />
  );
};

StatusQueue.propTypes = {};

StatusQueue.defaultProps = {};

export default StatusQueue;
