import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import * as Styled from "./ResetPassword.styles";
import styled from "styled-components";

const { Title } = Typography;

const Wrapper = styled.div(() => ({
  display: "flex",
  minHeight: "calc(100vh - 140px)",
  justifyContent: "center",
  alignItems: "center",
}));

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  padding-bottom: 10px;
`;

const ResetPassword = ({ onSubmit }) => {
  return (
    <Wrapper>
      <FormWrapper>
        <Row
          align="middle"
          justify="space-around"
          style={{ flex: 1, paddingBottom: "50px" }}
        >
          <Col span={18}>
            <ContentWrapper>
              {/* <LogoWrapper>
              <Logo src={LogoImg} />
            </LogoWrapper> */}
              <Title level={2}>Reset Password</Title>
            </ContentWrapper>
            <Form name="userform" autoComplete="off" onFinish={onSubmit}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Confirm Password" />
              </Form.Item>

              <Form.Item>
                <Button size="large" block type="primary" htmlType="submit">
                  {"Submit"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </FormWrapper>
    </Wrapper>
  );
};

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

export default ResetPassword;
