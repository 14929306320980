import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../user/userSlice";
import {
  downloadImageAction,
  fairUsageStatusAction,
  getHistoryAction,
  getQueueAction,
} from "./modelOutputActions";
// import { } from "./modelOutputActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  fairUsageStatus: true,
  downloadStatus: {
    loading: false,
    error: null,
  },
  history: {
    data: null,
    loading: false,
    error: null,
  },
  queue: {
    data: null,
    loading: false,
    error: null,
  },
};

const modelOutputSlice = createSlice({
  name: "modelOutput",
  initialState,
  reducers: {
    setfairUsageStatus: (state, { payload }) => {
      state.fairUsageStatus = payload;
    },
  },
  extraReducers: {
    [fairUsageStatusAction.fulfilled]: (state, { payload }) => {
      state.fairUsageStatus = payload;
    },
    [downloadImageAction.pending]: (state) => {
      state.downloadStatus.loading = true;
      state.downloadStatus.error = null;
    },
    [downloadImageAction.fulfilled]: (state) => {
      state.downloadStatus.loading = false;
    },
    [downloadImageAction.rejected]: (state, { payload }) => {
      state.downloadStatus.loading = false;
      state.downloadStatus.error = payload;
    },
    [getHistoryAction.pending]: (state) => {
      state.history.loading = true;
      state.history.error = null;
    },
    [getHistoryAction.fulfilled]: (state, { payload }) => {
      state.history.loading = false;
      state.history.data = payload;
    },
    [getHistoryAction.rejected]: (state, { payload }) => {
      state.history.loading = false;
      state.history.error = payload;
    },
    [getQueueAction.pending]: (state) => {
      state.queue.loading = true;
      state.queue.error = null;
    },
    [getQueueAction.fulfilled]: (state, { payload }) => {
      state.queue.loading = false;
      state.queue.data = payload;
    },
    [getQueueAction.rejected]: (state, { payload }) => {
      state.queue.loading = false;
      state.queue.error = payload;
    },
    [logout]: (state) => {
      return initialState;
    },
  },
});

export const { setfairUsageStatus } = modelOutputSlice.actions;

export const fairUsageStatusSelector = (state) =>
  state.modelOutput.fairUsageStatus;

export default modelOutputSlice.reducer;
