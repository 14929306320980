import React, { useEffect, useRef } from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { GlobalStyle } from "./ModelDetails.styles";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import ImageDetails from "../common/ImageDetails";
import { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 500px;
  gap: 40px;
  max-width: 1000px;
  margin: auto;

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;

    .image-details {
      order: 2;
    }
  }
`;

export const ImagePreviewModal = ({
  images = [],
  current,
  open,
  onClose,
  slideIndex = 0,
  aimodel,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(slideIndex);

  useEffect(() => {
    setSelectedIndex(slideIndex);
  }, [open]);

  const imageDetails = images[selectedIndex];

  return (
    <>
      <GlobalStyle />
      <Modal
        style={{ top: 0, minHeight: "100vh" }}
        open={open}
        width={"1200px"}
        footer={null}
        onCancel={onClose}
        wrapClassName="model-image-preview"
        maskClosable
        destroyOnClose
        closeIcon={<CloseOutlined style={{ fontSize: "25px" }} />}
      >
        <div
          style={{
            width: "1100px",
            height: "100%",
            maxWidth: "100%",
            paddingTop: "50px",
          }}
        >
          <Wrapper>
            <ImageDetails
              className="image-details"
              {...imageDetails}
              aimodel={aimodel}
              onLinkClick={onClose}
            />
            <ImageCarousel
              images={images.map((image) => image.image)}
              slideIndex={slideIndex}
              afterChange={(index) => setSelectedIndex(index)}
            />
          </Wrapper>
        </div>
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
