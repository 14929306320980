import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Styled from "./CreateGeneratorContainer.styles";
import {
  Button,
  Divider,
  Form,
  Typography,
  theme,
  Input,
  App,
  Collapse,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import { useNavigate } from "react-router-dom";
import UploadImage from "./UploadImage";
import BorderedDiv from "../../components/common/BorderedDiv";
import TextArea from "antd/es/input/TextArea";
import { useAxios } from "../../hooks/axios";
import { getFormData } from "../../utils/utils";
import { AdvanceSettingsCollapse } from "../../components/common/FormCommon";

const { Title } = Typography;

const CreateGeneratorContainer = (props) => {
  const { response, fetchData } = useAxios(
    {
      url: `/api/aimodels/train_custom_model/${
        process.env.REACT_APP_MOCK_REQUEST === "true"
          ? "?is_mock_request=True"
          : ""
      }`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    },
    {
      showGlobalLoader: true,
    }
  );

  const { response: allModels = [] } = useAxios(
    {
      url: "/api/aimodels/all_public_models/",
    },
    {
      showGlobalLoader: true,
      executeOnMount: true,
    }
  );

  const { notification } = App.useApp();

  const navigate = useNavigate();

  useEffect(() => {
    if (response?.id) {
      notification.success({
        message:
          "Training in progress. Please check generators list after sometime.",
      });
      navigate(`/generator/${response.internal_name}`);
    }
  }, [response?.id]);

  const { token } = theme.useToken();

  const [form] = Form.useForm();

  const [enableTrainingSteps, setEnableTrainingSteps] = useState(false);

  const handleEnableTrainingSteps = (e) => {
    setEnableTrainingSteps(e.target.checked);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = (data) => {
    const {
      name,
      images,
      numOfSteps,
      description,
      source,
      learningRate: learning_rate,
    } = data;
    const formattedData = {
      name,
      description,
      num_training_steps: enableTrainingSteps ? numOfSteps : undefined,
      source,
      learning_rate,
      images: images.map((image) => image.originFileObj),
    };
    fetchData({
      data: getFormData(formattedData),
    });
  };

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Title level={3}>Create Generator</Title>
      </Divider>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
          initialValues={{ learningRate: "2e-6" }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Generator name!",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter Generator description!",
              },
            ]}
          >
            <TextArea size="large" placeholder="Description" />
          </Form.Item>
          <AdvanceSettingsCollapse bordered={false} defaultActiveKey={[null]}>
            <Collapse.Panel header="Advance Settings" key="1" forceRender>
              <Form.Item name="source" label="Source">
                <Select
                  allowClear
                  options={
                    allModels.length
                      ? allModels.map((model) => ({
                          value: model.internal_name,
                          label: model.name,
                        }))
                      : []
                  }
                />
              </Form.Item>
              <Form.Item
                name="learningRate"
                label="Learning Rate"
                rules={[
                  {
                    required: true,
                    message: "Please enter Learning Rate!",
                  },
                ]}
              >
                <Input size="large" placeholder="Learning Rate" />
              </Form.Item>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  onChange={handleEnableTrainingSteps}
                  checked={enableTrainingSteps}
                ></Checkbox>
                <Form.Item
                  name="numOfSteps"
                  label="Number of Training Steps"
                  style={{ flex: "1", marginBottom: 0 }}
                >
                  <InputNumber size="large" disabled={!enableTrainingSteps} />
                </Form.Item>
              </div>
            </Collapse.Panel>
          </AdvanceSettingsCollapse>
          <BorderedDiv style={{ flex: "1 0 auto" }}>
            <Form.Item
              name="images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: "Images is required" }]}
            >
              <UploadImage />
            </Form.Item>
          </BorderedDiv>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              background: token.colorBgBase,
              padding: "15px 50px",
              textAlign: "right",
            }}
          >
            <Form.Item noStyle>
              <Button size="large" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

CreateGeneratorContainer.propTypes = {};

CreateGeneratorContainer.defaultProps = {};

export default CreateGeneratorContainer;
