import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Pagination.styles";
import { Pagination as PaginationBase, Typography } from "antd";
import styled from "styled-components";

const PaginationWrapper = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const { Link } = Typography;

const Pagination = (props) => {
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <Link>Previous</Link>;
    }
    if (type === "next") {
      return <Link>Next</Link>;
    }
    return originalElement;
  };
  return (
    <PaginationWrapper>
      <PaginationBase
        showSizeChanger={false}
        {...props}
        itemRender={itemRender}
      />
    </PaginationWrapper>
  );
};

Pagination.propTypes = {};

Pagination.defaultProps = {};

export default Pagination;
