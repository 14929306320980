import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Divider, Tabs, Typography } from "antd";
import { EyeOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
// import Category from "../Category/Category";
import ModelPreview from "../ModelPreview";
import { useIsOverflow } from "../../hooks/common";
import { useSearchParams } from "react-router-dom";
import {
  BannerWrapper,
  ImagePreviewWrapper,
  ImageWrapper,
  ImageOverlay,
  ShowMoreWrapper,
  MainTextWrapper,
  Price,
  ButtonWrapper,
  GenerateArea,
  GenerateImageWrapper,
  SampleImageArea,
  TokenWrapper,
} from "./ModelDetails.styles";
import { ImagePreviewModal } from "./ImagePreviewModal";

const { Title, Text } = Typography;

const ModelDetails = ({
  images = [],
  imagesArr = [],
  name,
  description,
  price,
  similarModels = [],
  id,
  onSubmitPrompt,
  onImageResponse,
  tokens,
  internal_name,
  is_processed: isProcessed,
}) => {
  const previewImage = imagesArr?.length ? imagesArr : Array(4).fill("");
  const demoRef = useRef(null);
  const imagePreviewWrapperRef = useRef(null);
  const [showMore, setShowMore] = useState(imagesArr.length <= 4);
  const toggleShowMore = () => {
    setShowMore((showMore) => !showMore);
    imagePreviewWrapperRef.current.scrollTop = 0;
  };

  const isOverflow = useIsOverflow(imagePreviewWrapperRef, 480);

  const [preview, setPreview] = useState(false);
  const [previewSlideIndex, setPreviewSlideIndex] = useState(0);

  const handleScrollToDemo = () => {
    if (demoRef?.current) {
      demoRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  let [searchParams] = useSearchParams();

  const initialPrompt = searchParams.get("prompt");
  const initialNegativePrompt = searchParams.get("negativePrompt");
  const initialGuidanceScale = searchParams.get("guidanceScale");

  useEffect(() => {
    if (initialPrompt) {
      handleScrollToDemo();
    }
  }, [initialPrompt]);

  const [generationInProgress, setGenerationInProgress] = useState(false);

  const handleSubmitPrompt = () => {
    onSubmitPrompt();
    setGenerationInProgress(true);
  };

  const handleImageGeneration = () => {
    onImageResponse();
    setGenerationInProgress(false);
  };

  const generationCommonProps = {
    modelId: id,
    prompt: initialPrompt,
    negativePrompt: initialNegativePrompt,
    guidanceScale: initialGuidanceScale,
    onSubmitPrompt: handleSubmitPrompt,
    onImageResponse: handleImageGeneration,
  };

  const items = [
    {
      key: "1",
      label: `Generate from Text`,
      disabled: generationInProgress,
      children: <ModelPreview {...generationCommonProps} />,
    },
    {
      key: "2",
      label: `Generate from Image`,
      disabled: generationInProgress,
      children: <ModelPreview {...generationCommonProps} withImage />,
    },
  ];

  return (
    <div>
      <ImagePreviewModal
        images={images}
        aimodel={{
          id,
          name,
          internal_name,
        }}
        onClose={() => setPreview(false)}
        open={preview}
        slideIndex={previewSlideIndex}
      />
      <BannerWrapper justify="center">
        <SampleImageArea>
          <ImagePreviewWrapper showMore={showMore} ref={imagePreviewWrapperRef}>
            {/* TODO: change fallback image */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {previewImage.map((img, index) => (
                <ImageWrapper
                  key={img}
                  style={{ backgroundImage: `url(${img})` }}
                  onClick={() => {
                    setPreview(true);
                    setPreviewSlideIndex(index);
                  }}
                >
                  <ImageOverlay>
                    <div>
                      <EyeOutlined style={{ fontSize: "18px" }} />
                      <p>Preview</p>
                    </div>
                  </ImageOverlay>
                </ImageWrapper>
              ))}
            </div>
          </ImagePreviewWrapper>
          {isOverflow && (
            <ShowMoreWrapper onClick={toggleShowMore}>
              <Button type="text" color="primary" size="small" block>
                {!showMore ? <CaretDownOutlined style={{ fontSize: 20 }} /> : <CaretUpOutlined style={{ fontSize: 20 }} />}
                {/* Show {showMore ? `Less` : `More`} */}
              </Button>
            </ShowMoreWrapper>
          )}
        </SampleImageArea>
        <div>
          <MainTextWrapper>
            <Title level={1}>{name}</Title>
            <Divider />
            <Text>{description}</Text>
            {tokens ? (
              <TokenWrapper>
                <Text strong>Token :</Text>{" "}
                <Text>
                  Use the token{" "}
                  <Text style={{ fontSize: "18px" }} copyable code>
                    {tokens}
                  </Text>{" "}
                  in your prompts for the effect.
                </Text>{" "}
              </TokenWrapper>
            ) : null}
            {/* <Price level={3}>{price}</Price> */}
            {isProcessed ? (
              <ButtonWrapper>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={handleScrollToDemo}
                >
                  Try it out
                </Button>
              </ButtonWrapper>
            ) : (
              <Alert
                style={{ marginTop: "20px" }}
                message="Generator is still training. Please check back after sometime."
                type="info"
                showIcon
              />
            )}
          </MainTextWrapper>
        </div>
      </BannerWrapper>

      {isProcessed ? (
        <>
          <Divider />
          <GenerateArea ref={demoRef}>
            <GenerateImageWrapper>
              <Title align="center" level={3}>
                Generate Images
              </Title>
              <Tabs defaultActiveKey="1" items={items} />
            </GenerateImageWrapper>
          </GenerateArea>
          <Divider />
        </>
      ) : null}
      {/* <Spacer>
        <Category title="Similar Models" items={similarModels} />
      </Spacer> */}
    </div>
  );
};

ModelDetails.propTypes = {};

ModelDetails.defaultProps = {};

export default ModelDetails;
