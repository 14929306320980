import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import * as Styled from "./TagInput.styles";
import { Form, message, Tag, theme } from "antd";
import styled, { css } from "styled-components";
import { useEffect } from "react";
import { moveCursorToEnd } from "../../utils/utils";

const Wrapper = styled.div`
  ${({ status, placeholder, token }) => css`
    overflow: hidden;
    border: 1px solid ${token.colorBorder};
    cursor: text;
    background-color: ${token.colorBgContainer};
    // background-color: #172e3f;
    transition: all 0.3s;
    padding: 7px 11px;
    border-radius: 6px;
    min-height: 48px;
    font-weight: 400;

    .inputClass {
      background: none;
      border: none;
      outline: none;
      box-shadow: none !important;
      // color: #fff;
      display: inline-block;
      width: 100%;
      line-height: 30px;
      position: relative;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      min-width: 90px;

      // & * {
      //   color: #fff !important;
      // }
    }

    .inputClass:empty::before {
      content: "${placeholder}";
      opacity: 0.4;
      white-space: nowrap;
    }

    &:hover,
    &:focus,
    &:focus-within {
      border: 1px solid ${token.colorPrimaryHover};
    }
    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 ${token.controlOutlineWidth}px ${token.controlOutline};
    }

    .ant-tag {
      padding: 4px 7px;
    }

    ${status === "error" &&
    css`
      border: 1px solid ${token.colorError};
      &:hover {
        border-color: ${token.colorErrorBorderHover};
      }

      &:focus,
      &:focus-within {
        border: 1px solid ${token.colorError};
        box-shadow: 0 0 0 ${token.controlOutlineWidth}px
          ${token.colorErrorOutline};
      }
    `}
  `}
`;

const TagContent = styled.span`
  max-width: calc(100% - 15px);
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
`;

const TagList = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  margin: 0;
  flex-wrap: wrap;
  row-gap: 8px;
  width: 100%;
`;

const TagInput = (props, ref) => {
  const {
    style,
    onChange,
    onInputChange,
    valueInput: valueInputProp,
    onTagSubmit,
    placeholder = "Enter Tag",
    inputStyles = {},
  } = props;
  const [value, setValue] = useState(props.value || []);
  const [valueInput, setValueInput] = useState(valueInputProp || "");
  const inputRef = useRef(null);
  const { status } = Form.Item.useStatus();
  const { token } = theme.useToken();

  useImperativeHandle(ref, () => ({
    changeVal: () => {
      return value;
    },
  }));

  useEffect(() => {
    // to reset input value in field value on form reset fields
    if (valueInputProp) {
      onChange?.([...value, valueInputProp]);
      onInputChange?.(valueInputProp);
      inputRef.current.innerHTML = valueInputProp;
    }
  }, []);

  const handleTagsChange = (newValue) => {
    setValue(newValue);
    onChange?.(newValue);
    onTagSubmit?.(newValue);
  };

  function preventDefault(str, e) {
    e.preventDefault();
    handleTagsChange(value.filter((item) => item !== str));
  }

  function focus() {
    inputRef.current && inputRef.current.focus();
  }

  const updateInput = (newValue) => {
    setValueInput(newValue);
    onInputChange?.(newValue);
  };

  function handleChange(e) {
    let elm = e.currentTarget;
    updateInput(elm.textContent);
    onChange?.([...value, elm.textContent]);
    if (e.nativeEvent.inputType === "insertFromPaste") {
      elm.innerHTML = elm.textContent;
      moveCursorToEnd(elm);
    }
  }

  function keyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (valueInput) {
        const newTags = valueInput.split(",").filter((tag) => tag.trim());
        handleTagsChange([...value, ...newTags]);
        updateInput("");
      } else {
        message.error("");
      }
      inputRef.current.innerHTML = "";
      return false;
    }
    if (e.keyCode === 8 && !valueInput) {
      handleTagsChange(
        value.filter(function (v, i, ar) {
          return i !== ar.length - 1;
        })
      );
    }
  }

  return (
    <div style={style}>
      <Wrapper
        onClick={focus}
        status={status}
        placeholder={placeholder}
        token={token}
      >
        <TagList>
          {value &&
            value.map((item, index) => (
              <li key={index} style={{ maxWidth: "100%" }}>
                <Tag
                  closable
                  color={token.colorPrimary}
                  onClose={(e) => preventDefault(item, e)}
                  style={{ maxWidth: "calc(100% - 8px)" }}
                >
                  <TagContent>{item}</TagContent>
                </Tag>
              </li>
            ))}
          <li style={{ flex: "1", display: "flex", maxWidth: "100%" }}>
            <div
              contentEditable
              className="inputClass"
              onKeyDown={keyDown}
              onInput={handleChange}
              ref={inputRef}
              style={{ ...inputStyles }}
            ></div>
          </li>
        </TagList>
      </Wrapper>
    </div>
  );
};

export default forwardRef(TagInput);
