import React, { useEffect } from "react";
import { Alert, Space, Avatar, Typography, Tag } from "antd";
import {
  LoadingOutlined,
  UserOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { getUserDetails } from "../../features/user/userActions";
// import { logout } from "../../features/user/userSlice";
import { auth, logout } from "../../firebase";
import ResendEmailButton from "../ResendEmailButton/ResendEmailButton";
import { setUser } from "../../features/user/userSlice";
import { getAvailableCreditsAction } from "../../features/credits/creditsActions";
import { getAvailableCreditsCount } from "../../features/credits/creditsSlice";
import { HIDE_SIGNUP } from "../../config";
import { useErrorNotification } from "../../hooks/common";
import { MenuItem, AlertContent, HeaderBase } from "../common/HeaderBase";

const { Text } = Typography;

const getNavItems = ({ onLogout, availableCredits, creditCountLoading }) => [
  {
    label: <Link to="/">Home</Link>,
    key: "home",
  },
  {
    label: <Link to="/generators">Generators</Link>,
    key: "generators",
  },
  {
    label: <Link to="/login">Login</Link>,
    key: "login",
  },
  {
    label: (
      <>
        <Tag style={{ fontSize: "15px" }} className="creditNavItem">
          <Text
            style={{
              fontSize: "15px",
            }}
            italic
          >
            Credits:
            <span
              style={{
                marginLeft: "5px",
                minWidth: "50px",
                display: "inline-block",
              }}
            >
              {creditCountLoading ? <LoadingOutlined /> : availableCredits}
            </span>
          </Text>
        </Tag>
        <Text italic className="creditNavItem-mobile">
          Credits:
          <span style={{ marginLeft: "5px" }}>
            {creditCountLoading ? <LoadingOutlined /> : availableCredits}
          </span>
        </Text>
      </>
    ),
    key: "available-credits",
  },
  {
    label: <Link to="/signup">Sign Up</Link>,
    key: "signup",
  },
  {
    label: <Link to="/buy-credits">Buy Credits</Link>,
    key: "buy-credits",
  },
  {
    label: (
      <Link to="/" onClick={() => onLogout()}>
        Logout
      </Link>
    ),
    key: "logout",
  },
  {
    label: <Link to="/history">History</Link>,
    key: "history",
  },
  {
    label: <Link to="/create-generator">Create Generator</Link>,
    key: "create-generator",
  },
  {
    label: (
      <MenuItem>
        <span className="user-nav-item-dropdown">
          <Avatar
            style={{ marginRight: "4px" }}
            size={33}
            icon={<UserOutlined />}
          />
          <CaretDownOutlined className="profileCaretIcon" />
        </span>
      </MenuItem>
    ),
    key: "user",
    children: [
      {
        label: <Link to="/profile">Profile</Link>,
        key: "profile",
      },
      {
        label: <Link to="/buy-credits">Buy Credits</Link>,
        key: "buy-credits",
      },
      {
        label: <Link to="/favorites">Favorites</Link>,
        key: "favorites",
      },
      {
        label: (
          <Link to="/" onClick={() => onLogout()}>
            Logout
          </Link>
        ),
        key: "logout",
      },
    ],
  },
];

const commonNav = ["home", "generators"];
const guestUserNav = !HIDE_SIGNUP
  ? [...commonNav, "buy-credits", "login", "signup"]
  : [...commonNav, "buy-credits", "login"];
const loggedInNav = [
  ...commonNav,
  "create-generator",
  "history",
  "available-credits",
  "user",
];

const AppHeader = () => {
  // const [availableCredits, setAvailableCredits] = useState("");
  const location = useLocation();
  const { userInfo, userToken, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    data: availableCredits,
    loading: creditCountLoading,
    error: creditCountError,
  } = useSelector(getAvailableCreditsCount);

  useErrorNotification(creditCountError);

  useEffect(() => {
    if (userToken) {
      dispatch(getAvailableCreditsAction());
    }
  }, [userToken, dispatch]);

  useEffect(() => {
    const refreshUser = () => {
      if (!document.hidden) {
        auth?.currentUser?.reload &&
          auth?.currentUser
            ?.reload()
            .then((user) => {
              const newUser = auth?.currentUser;
              if (newUser?.accessToken) {
                dispatch(setUser(newUser));
              }
              if (newUser === null || newUser === undefined) {
                logout();
              }
            })
            .catch((err) => {
              console.error(err.message);
            });
      }
    };
    document.addEventListener("visibilitychange", refreshUser);
    return () => document.removeEventListener("visibilitychange", refreshUser);
  }, []);

  const items = getNavItems({
    onLogout: logout,
    availableCredits,
    creditCountLoading,
  });
  const navItems = (userInfo ? loggedInNav : guestUserNav).map((nav) => {
    return items.find((item) => item.key === nav);
  });

  const verifyEmailAlert =
    userInfo &&
    !userInfo?.emailVerified &&
    location.pathname !== "/verify-email" ? (
      <Alert
        description={
          <AlertContent>
            A Verification email has been sent to:{" "}
            <strong>{userInfo?.email}</strong>. Follow the instruction in the
            email to verify your account.
            <ResendEmailButton currentUser={userInfo} />
          </AlertContent>
        }
        type="info"
        action={<Space direction="vertical"></Space>}
        closable
      />
    ) : null;

  return (
    <>
      {verifyEmailAlert}
      <HeaderBase loading={loading} navItems={navItems}></HeaderBase>
    </>
  );
};

export default AppHeader;
