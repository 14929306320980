import { Button, Typography, Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ImageCard from "../Card";

const { Title, Text } = Typography;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const DetailsItemWrapper = styled.div`
  margin-bottom: 20px;
  .label {
    font-size: 12px;
    display: inline-block;
  }
`;

const DetailsItem = ({ label, children }) => {
  return (
    <DetailsItemWrapper>
      <div>
        <Text type="secondary" strong className="label">
          {label}
        </Text>
      </div>
      {children}
    </DetailsItemWrapper>
  );
};

function ImageDetails({
  prompt,
  aimodel,
  negative_prompt,
  input_image,
  guidance_scale,
  onLinkClick,
  className = "",
}) {
  const paramsObj = {};
  if (prompt) {
    paramsObj.prompt = prompt;
  }
  if (guidance_scale) {
    paramsObj.guidanceScale = guidance_scale;
  }
  if (negative_prompt) {
    paramsObj.negativePrompt = negative_prompt;
  }
  const searchParams = new URLSearchParams(paramsObj);

  const handleLinkClick = () => {
    onLinkClick?.();
  };

  return (
    <div className={className}>
      <DetailsWrapper>
        <div>
          {prompt ? (
            <DetailsItem label="Prompt">
              <Card>
                <Text copyable>{prompt}</Text>
              </Card>
            </DetailsItem>
          ) : null}
          {negative_prompt ? (
            <DetailsItem label="Negative Prompt">
              <Card>
                <Text copyable>{negative_prompt}</Text>
              </Card>
            </DetailsItem>
          ) : null}
          {input_image ? (
            <DetailsItem label="Input Image">
              <ImageCard image={input_image} noTitle size="small" />
            </DetailsItem>
          ) : null}
          <DetailsItem label="Modal Name" span={3}>
            <Link
              to={`/generator/${aimodel.internal_name}`}
              onClick={handleLinkClick}
            >
              {aimodel.name}
            </Link>
          </DetailsItem>
          {guidance_scale ? (
            <DetailsItem label="Guidance Scale">
              <Text>{guidance_scale}</Text>
            </DetailsItem>
          ) : null}
        </div>
        <Link
          to={`/generator/${aimodel.internal_name}?${searchParams.toString()}`}
          onClick={handleLinkClick}
        >
          <Button type="primary">Generate more images</Button>
        </Link>
      </DetailsWrapper>
    </div>
  );
}

export default ImageDetails;
