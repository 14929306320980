import { useSelector } from "react-redux";

export const useIsLogin = () => {
  const { userToken } = useSelector((state) => state.user);
  return !!userToken;
};

export const useAuthToken = () => {
  const { userToken } = useSelector((state) => state.user);
  return userToken;
};
