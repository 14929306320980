import React from "react";
import { useEffect } from "react";
import { Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailImage from "../../images/email-verification-code.svg";
import ResendEmailButton from "../../components/ResendEmailButton/ResendEmailButton";

function VerifyEmail() {
  const { userInfo: currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  let { intialResendDisable } = location.state || {};

  useEffect(() => {
    if (currentUser?.emailVerified) {
      navigate("/");
    }
  }, [navigate, currentUser]);

  return (
    <Result
      icon={<img src={EmailImage} height={300} alt="Verify Email" />}
      title="Verify your Email Address"
      subTitle={
        <>
          A Verification email has been sent to:{" "}
          <strong>{currentUser?.email}</strong>
          <br />
          Follow the instruction in the email to verify your account
        </>
      }
      extra={
        <ResendEmailButton
          intialResendDisable={intialResendDisable}
          currentUser={currentUser}
        />
      }
    />
  );
}

export default VerifyEmail;
