import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Search.styles";
import { Button, Form, Input } from "antd";
import styled from "styled-components";
import { useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;

  .ant-select {
    width: 200px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .ant-select {
      width: auto;
    }
  }
`;

const Search = ({ onSearch, searchText = "" }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      searchText,
    });
  }, [searchText]);

  const onValuesChange = (onValuesChange) => {
    if (searchText && !onValuesChange.searchText) {
      onSearch({
        searchText: "",
      });
    }
  };

  return (
    <Form
      name="customized_form_controls"
      layout="inline"
      initialValues={{
        searchText,
      }}
      onFinish={onSearch}
      onValuesChange={onValuesChange}
      form={form}
      validateTrigger="onSubmit"
    >
      <Wrapper>
        <Form.Item
          name="searchText"
          style={{ flex: 1 }}
          rules={[{ required: true, message: "Please input search term!" }]}
        >
          <Input size="large" placeholder="Search Model" allowClear />
        </Form.Item>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Wrapper>
    </Form>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
