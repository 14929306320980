import { Button, Typography, Grid } from "antd";
import React from "react";
import styled from "styled-components";
import Section from "./Section";
import SectionHolder from "./SectionHolder";
import bannerBg from "../../../images/landing-page/pixeous-banner.webp";
import SignupInput from "./SignupInput";

const { useBreakpoint } = Grid;

const ContentLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 100;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
`;

const Wrapper = styled.div`
  background-image: linear-gradient(-45deg, #e73c7e, #6633d9, #7541ea);
  background-size: 100% 100%;
  padding: 0px 40px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  :after {
    width: 200%;
    height: 200%;
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    display: block;
    background-image: url(${bannerBg});
    background-size: 1200px;
    transform: rotate(20deg);
    opacity: 0.15;

    @media screen and (max-width: 768px) {
      top: 0;
      left: -50%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2px 20px;
    :after {
      left: -50px;
    }
  }
`;

function EarlySignup() {
  const breakpoint = useBreakpoint();
  return (
    <SectionHolder>
      <Wrapper id="early-access">
        <div style={{ padding: "60px 0" }}>
          <ContentLayout>
            <div>
              <Typography.Title
                level={breakpoint.md ? 1 : 3}
                style={{ color: "#fff", marginBottom: "5px", marginTop: 0 }}
              >
                Want to get early access?
              </Typography.Title>
              <Typography.Paragraph level={5} style={{ color: "#fff" }}>
                Sign up today to be the first to try out our latest innovations!
              </Typography.Paragraph>
            </div>
            <SignupInput style={{ width: "400px", maxWidth: "100%" }} />
            {/* <SignupButton size="large">Sign up Now</SignupButton> */}
          </ContentLayout>
        </div>
      </Wrapper>
    </SectionHolder>
  );
}

export default EarlySignup;
