import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./PromptHistoryItem.styles";
import styled from "styled-components";
import { Divider, Typography } from "antd";
import { API_BASE_URL } from "../../config";
import ImageDetails from "../common/ImageDetails";
import ImageCarousel from "../ImageCarousel/ImageCarousel";

const { Title } = Typography;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 500px;
  gap: 40px;
  max-width: 1000px;
  margin: auto;

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;

    .image-details {
      order: 2;
    }
  }
`;

const PromptHistoryItem = ({
  promptDetails,
  onDownload,
  previewImageIndex = 0,
}) => {
  const { images } = promptDetails;

  const handleDownload = (index) => {
    const imgObj = images[index];
    onDownload({
      prompt_id: imgObj.user_prompt,
      image_id: imgObj.id,
    });
  };

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Title level={3}>Preview</Title>
      </Divider>
      <Wrapper>
        <ImageDetails {...promptDetails} />
        <ImageCarousel
          images={images.map(({ image }) => `${API_BASE_URL}/${image}`)}
          onDownload={handleDownload}
          slideIndex={previewImageIndex}
        />
      </Wrapper>
    </>
  );
};

PromptHistoryItem.propTypes = {};

PromptHistoryItem.defaultProps = {};

export default PromptHistoryItem;
