import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import imagesSvg from "../../../images/images.svg";
import computerSvg from "../../../images/computer.svg";
import creativeSvg from "../../../images/landing-page/creative-mind.svg";
import trainImg from "../../../images/landing-page/brain.svg";
import exisitingModelImg from "../../../images/landing-page/existing.svg";
import Section from "./Section";

const { Title, Paragraph } = Typography;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  row-gap: 50px;
  column-gap: 4vw;

  @media screen and (max-width: 900px) {
    column-gap: 3vw;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .step-card {
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    max-width: 400px;
    /* margin: 0 auto; */
    padding: 20px 20px 20px 0;
    border-radius: 10px;
    text-align: center;
    /* background: #f2f2f9; */
    justify-content: center;
    /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 16px 32.77px 0px,
      rgba(0, 0, 0, 0.06) 0px 32px 65.54px 0px,
      rgba(0, 0, 0, 0.03) 0px 64px 131.07px 0px; */
  }
  .step-icon {
    height: 90px;
    width: auto;
    margin-bottom: 20px;
    align-self: center;
  }
  .step-description {
    text-align: center;
    font-size: 16px;
  }
  .creative-card {
    .step-icon {
      margin-left: -16px;
    }
  }
  .step-title {
    color: #000 !important;
  }
  .existing-card {
    .step-title {
      margin-right: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    .step-card {
      text-align: center;
      margin: auto;
    }
    .step-icon {
      align-self: center;
    }
    .step-description {
      text-align: center;
    }
  }
`;

const StepCard = ({ icon = imagesSvg, title, description, className = "" }) => {
  return (
    <div className={`${className} step-card`}>
      <img src={icon} className="step-icon" />

      <Title level={2} className="step-title">
        {title}
      </Title>
      <Paragraph type="secondary" className="step-description">
        {description}
      </Paragraph>
    </div>
  );
};

function Usage() {
  return (
    <Section title="Ways to Use">
      <Wrapper>
        <StepCard
          className="existing-card"
          icon={exisitingModelImg}
          title="Use existing models"
          description="Use a general or fine-tuned model to generate production-ready assets."
        ></StepCard>
        <StepCard
          icon={trainImg}
          title="Train Your Own AI Models"
          description="In just a few clicks, you can train your own AI model to generate specific objects, humans, concepts or styles."
        ></StepCard>
        <StepCard
          icon={creativeSvg}
          className="creative-card"
          title="Get Creative Suggestions"
          description="Get AI powered suggestions to generate more creative variations of the same asset."
        ></StepCard>
      </Wrapper>
    </Section>
  );
}

export default Usage;
