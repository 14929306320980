import styled, { createGlobalStyle } from "styled-components";
import { Typography } from "antd";
import customTheme from "../../theme-overrides";

const { Title } = Typography;

export const GlobalStyle = createGlobalStyle`
.model-image-preview{
/* .ant-modal-content {
    background: transparent;
    padding: 0;
  } */
  .ant-modal-close {
    width: 28px;
    height: 28px;
    padding: 2px;
  }
}

.model-image-preview {
.ant-modal-content {
    background-color: ${customTheme.token.colorBgBase};
    overflow: auto;
    min-height: 100vh;
  }
  .ant-modal{
    max-width: 100vw;
    padding-bottom: 0;
  }
}
`;

export const CarouselWrapper = styled.div`
  margin: auto;
  width: 500px;
`;

export const BannerWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 500px 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 350px 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }
`;
export const ShowMoreWrapper = styled.div`
  position: absolute;
  content: "";
  width: 100%;
  height: 40px;
  bottom: -35px;
  left: 0;
  // background-image: linear-gradient(to bottom, transparent, white);
  text-align: center;
  padding: 10px;
`;
export const ImagePreviewWrapper = styled.div`
  padding: 0 10px;
  overflow: ${(p) => (p.showMore ? "auto" : "hidden")};

  transition: max-height 0.15s linear;
  max-height: ${(p) => (p.showMore ? "700px" : "480px")};

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  .ant-image {
    text-align: center;
  }

  .ant-image-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }
`;
export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  opacity: 0;
  color: #fff;
  transition: opacity 0.2s ease-in;
`;
export const ImageWrapper = styled.div`
  cursor: pointer;
  padding: 5px;
  position: relative;
  width: 236px;
  height: 236px;
  flex: 1;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  margin: 2px;
  border-radius: 5px;

  @media (max-width: 900px) {
    width: 165px;
    height: 165px;
  }

  @media (max-width: 768px) {
    width: 236px;
    height: 236px;
  }

  @media (max-width: 520px) {
    width: 165px;
    height: 165px;
  }

  &:hover {
    ${ImageOverlay} {
      opacity: 1;
    }
  }
`;
export const MainTextWrapper = styled.div`
  padding: 20px;
`;
export const Price = styled(Title)`
  margin-top: 1em !important;
  margin-bottom: 1em !important;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  max-width: 300px;
  margin-top: 20px;
`;
const Spacer = styled.div`
  padding: 20px 0;
`;
export const PreviewSlide = styled.div`
  img {
    margin: auto;
    max-width: 100%;
  }
  /* width: 100vw;
  height: 100vh;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }

  &:before {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    content: "";
    // background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
  } */
`;
export const GenerateArea = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;
export const GenerateImageWrapper = styled.div`
  flex: 2;
  /* margin: 0 auto; */
  max-width: 1000px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    max-width: 100%;
  }
`;

export const SampleImageArea = styled.div`
  position: relative;
  max-width: 500px;
  margin: auto;
  width: 100%;
`;

export const TokenWrapper = styled.div`
  margin-top: 20px;
`;
