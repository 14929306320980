import { configureStore } from "@reduxjs/toolkit";
import modelsReducer from "../features/models/modelsSlice";
import userReducer from "../features/user/userSlice";
import creditsReducer from "../features/credits/creditsSlice";
import modelOutput from "../features/modelOutput/modelOutputSlice";
import global from "../features/global/globalSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    models: modelsReducer,
    credits: creditsReducer,
    modelOutput,
    global,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
