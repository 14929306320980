import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./ModelCardWithActions.styles";
import { useDispatch, useSelector } from "react-redux";
import { addToFavoriteAction } from "../../features/models/modelsActions";
import Card from "../Card";
import { Tag } from "antd";
import { Link } from "react-router-dom";

const ModelCardWithActions = ({ model, ...props }) => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);

  const onFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(addToFavoriteAction({ id: model.id, favorite: !model.favorite }));
  };

  const { internal_name } = model;

  const component = (
    <Card
      {...props}
      onFavorite={onFavorite}
      favorite={model.favorite}
      withFavorite={!!userToken}
      tag={!model.is_processed ? <Tag color="#674aea">Processing</Tag> : null}
    />
  );
  if (internal_name) {
    return <Link to={`/generator/${internal_name}`}>{component}</Link>;
  }
  return component;
};

ModelCardWithActions.propTypes = {};

ModelCardWithActions.defaultProps = {};

export default ModelCardWithActions;
