import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Banner.styles";
import { Button, Col, Row, Typography } from "antd";
import Card from "../ModelCardWithActions";
import styled from "styled-components";
import { Link } from "react-router-dom";

const { Title } = Typography;

const Wrapper = styled.div`
  padding: 40px 0;
  max-width: 1200px;
  margin: auto;
  min-height: 65vh;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  @media (max-width: 990px) {
    text-align: center;
    align-items: center;
  }
`;

const TilesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  min-width: 500px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

const Banner = ({ top4Models = [], loadingTop4Models }) => {
  const finalTopModels =
    loadingTop4Models || !top4Models?.length ? Array(4).fill({}) : top4Models;
  return (
    <Wrapper>
      <Row align="center">
        <Col span={24} md={20} lg={11} style={{ flex: "1 1" }}>
          <ContentWrapper>
            <Title level={1}>
              DALL·E, GPT-3, Midjourney, Stable Diffusion Prompt Marketplace
            </Title>
            <Title level={3} style={{ margin: 0 }}>
              Find top prompts, produce better results, save on API costs, sell
              your own prompts.
            </Title>
            <ButtonWrapper>
              <Link to="/generators">
                <Button type="primary" onClick={() => {}} size="large">
                  Explore Generators
                </Button>
              </Link>
            </ButtonWrapper>
          </ContentWrapper>
        </Col>
        <Col
          span={0}
          lg={{
            span: 11,
            offset: 1,
          }}
          style={{ flex: "1 0 500px", minWidth: "500px" }}
          className="cardsColumn"
        >
          <TilesWrapper>
            {finalTopModels.map((model, index) => (
              <Card
                model={model}
                key={index}
                image={model?.banner_image}
                title={model?.name}
                size="small"
              />
            ))}
          </TilesWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
};

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
