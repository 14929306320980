import { debugErrorMap } from "firebase/auth";

const debugErrorMessageMap = debugErrorMap();

export const ERROR_MAP = Object.keys(debugErrorMessageMap).reduce(
  (acc, key) => {
    return {
      ...acc,
      [`auth/${key}`]: debugErrorMessageMap[key],
    };
  },
  {}
);
