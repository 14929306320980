import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Styled from "./ProgressTimer.styles";
import { useRef } from "react";
import { useState } from "react";
import { Typography } from "antd";

const { Text } = Typography;

const ProgressTimer = ({ totalTime, timeoutAt, onTimeout }) => {
  const timer = useRef(null);
  const [time, setTime] = useState(0);

  const stopTimer = () => {
    clearInterval(timer.current);
    onTimeout?.();
  };

  useEffect(() => {
    const timeoutTimeStamp = new Date();
    timeoutTimeStamp.setSeconds(timeoutTimeStamp.getSeconds() + timeoutAt);
    timer.current = setInterval(() => {
      setTime((time) => {
        if (time > timeoutAt) {
          stopTimer();
        }
        return time + 0.1;
      });
    }, 100);
    return () => clearInterval(timer.current);
  }, [totalTime, timeoutAt]);

  return (
    <Text style={{ fontFamily: "monospace" }}>
      processing {time.toFixed(1)}/{totalTime}s
    </Text>
  );
};

ProgressTimer.propTypes = {};

ProgressTimer.defaultProps = {};

export default ProgressTimer;
