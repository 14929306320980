import React from "react";
import { useEffect } from "react";
import { usePolling } from "../../hooks/common";
import { useGetQueue } from "../../hooks/modelHooks";
import { useAuthToken, useIsLogin } from "../../hooks/user";
import { filterInprogressItems } from "../../utils/utils";
import FloatingStatusQueue from "./FloatingStatusQueue";

function FloatingStatusQueueContainer() {
  const { getQueue, data: queueData } = useGetQueue();
  const userToken = useAuthToken();
  const queueResults = queueData?.data?.results || [];
  const refreshQueue = () => {
    getQueue(1);
  };

  const {
    start: startPoll,
    stop: stopPoll,
    isPolling,
  } = usePolling(refreshQueue, 5000);

  const inProgressItems = filterInprogressItems(queueResults);

  useEffect(() => {
    if (inProgressItems.length) {
      startPoll();
    } else if (isPolling) {
      stopPoll();
    }
  }, [JSON.stringify(inProgressItems), startPoll, stopPoll]);

  useEffect(() => {
    refreshQueue();
  }, [userToken]);

  return queueResults.length ? (
    <FloatingStatusQueue queueData={queueResults} />
  ) : null;
}

export default FloatingStatusQueueContainer;
