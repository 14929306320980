import { Spin } from "antd";
import RouteApp from "./containers/RouteApp/RouteApp";
import "antd/dist/reset.css";
import { useDispatch } from "react-redux";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { logout, setUser, getUserInit } from "./features/user/userSlice";
import styled from "styled-components";
import { theme } from "antd";
import GlobalContainer from "./containers/GlobalContainer/GlobalContainer";

const { useToken } = theme;

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(p) => p.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const dispatch = useDispatch();
  const [initUserLoading, setInitUserLoading] = useState(true);
  const { token } = useToken();
  useEffect(() => {
    // setFetching(true);
    setInitUserLoading(true);
    dispatch(getUserInit());
    onAuthStateChanged(auth, (user) => {
      setInitUserLoading(false);
      // setCurrentUser(user);
      // setFetching(false);
      if (user?.accessToken) {
        dispatch(setUser(user));
      }
      if (user === null) {
        dispatch(logout());
      }
    });
  }, []);

  return (
    <>
      {!initUserLoading ? (
        <>
          <GlobalContainer />
          <RouteApp />
        </>
      ) : (
        <LoaderWrapper bgColor={token.colorBgBase}>
          <Spin />
        </LoaderWrapper>
      )}
    </>
  );
}

export default App;
