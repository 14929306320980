import { createSlice } from "@reduxjs/toolkit";
import {
  getUserProfile,
  registerUser,
  updateUserProfile,
  userLogin,
} from "./userActions";

// // initialize userToken from local storage
// const userToken = localStorage.getItem("userToken")
//   ? localStorage.getItem("userToken")
//   : null;

const initialState = {
  loading: false,
  userInfo: null,
  userToken: null,
  error: null,
  success: false,
  userProfile: {
    data: null,
    loading: false,
    error: null,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.userProfile = initialState.userProfile;
    },
    getUserInit: (state) => {
      state.loading = true;
    },
    setUser: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.accessToken;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearSuccess: (state) => {
      state.success = false;
    },
    clearTempData: (state) => {
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    // [userLogin.fulfilled]: (state, { payload }) => {
    //   state.loading = false;
    //   state.userInfo = payload;
    //   state.userToken = payload.token;
    // },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get user details
    [getUserProfile.pending]: (state) => {
      state.userProfile.loading = true;
    },
    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.userProfile.loading = false;
      state.userProfile.data = payload;
    },
    [getUserProfile.rejected]: (state, { payload }) => {
      state.userProfile.loading = false;
      state.userProfile.error = payload;
    },
    [updateUserProfile.pending]: (state) => {
      state.userProfile.error = null;
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.userProfile.loading = false;
      state.userProfile.data = { ...state.userProfile.data, ...payload };
    },
    [updateUserProfile.rejected]: (state, { payload }) => {
      state.userProfile.loading = false;
      state.userProfile.error = payload;
    },
  },
});

export const {
  logout,
  getUserInit,
  setUser,
  clearError,
  clearSuccess,
  clearTempData,
} = userSlice.actions;

export const getUserProfileSelector = (state) => {
  return state.user.userProfile;
};

export default userSlice.reducer;
