import React from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
} from "antd";
import LogoImg from "../../images/p.svg";
import { Link } from "react-router-dom";
import { googleLogin } from "../../features/user/userActions";
import GoogleButton from "react-google-button";
import { HIDE_SIGNUP } from "../../config";

const { Title, Text } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  padding-bottom: 10px;
`;

const Logo = styled.img`
  width: 40px;
`;

const LogoWrapper = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
`;

const TextWrapper = styled.div`
  text-align: center;
`;

const Login = ({ signUp, onSubmit, error = [], onCloseError }) => {
  const onFinish = (values) => {
    onSubmit(values);
  };

  const submitGoogleLogin = async () => {
    await googleLogin();
  };

  let errorList = null;
  if (error?.length) {
    errorList = Array.isArray(error) ? (
      error.map((errorText) => <Typography.Text>{errorText}</Typography.Text>)
    ) : (
      <Typography.Text>{error}</Typography.Text>
    );
  }
  return (
    <Wrapper>
      <Row
        align="middle"
        justify="space-around"
        style={{ flex: 1, paddingBottom: "50px" }}
      >
        <Col span={18}>
          <ContentWrapper>
            {/* <LogoWrapper>
              <Logo src={LogoImg} />
            </LogoWrapper> */}
            <Title level={2}>{signUp ? "Sign Up" : "Login"}</Title>
          </ContentWrapper>
          {errorList && (
            <Alert
              style={{ marginBottom: "10px" }}
              message={errorList}
              type="error"
              showIcon
              closable
              onClose={onCloseError}
            />
          )}
          <Form name="userform" autoComplete="off" onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              style={{ marginBottom: 16 }}
            >
              <Input.Password size="large" placeholder="Password" />
            </Form.Item>
            {!signUp && (
              <Form.Item>
                <Row justify={"end"}>
                  <Col style={{ textAlign: "right" }}>
                    <Link to="/forgot-password">Forgot password?</Link>
                  </Col>
                </Row>
              </Form.Item>
            )}
            <Form.Item>
              <Button size="large" block type="primary" htmlType="submit">
                {signUp ? "Sign Up" : "Login"}
              </Button>
            </Form.Item>
          </Form>
          {!HIDE_SIGNUP ? (
            <>
              <Divider plain>or</Divider>
              <GoogleButton
                style={{ width: "100%" }}
                onClick={() => submitGoogleLogin()}
              />
              <br></br>
              {/* <br></br>
                <button
                  className=""
                  onClick={() =>
                    sendPasswordResetEmail(this.userform.userform_email.value)
                  }
                >
                  Send password reset email
              </button> */}
              <TextWrapper>
                {signUp ? (
                  <Text>
                    Already a member? <Link to="/login">Sign In</Link>
                  </Text>
                ) : (
                  <Text>
                    Not a member? <Link to="/signup">Signup</Link>
                  </Text>
                )}
              </TextWrapper>
            </>
          ) : null}
        </Col>
      </Row>
    </Wrapper>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
