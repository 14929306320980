import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./RouteApp.styles";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import PageLayout from "../../components/PageLayout/PageLayout";
import Home from "../Home/Home";
import Search from "../Search/Search";
import { Button, Result } from "antd";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import Details from "../Details/Details";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import EmailActionHandler from "../EmailActionHandler/EmailActionHandler";
import ForgotPasswordContainer from "../ForgotPasswordContainer";
import PrivateRoute from "./PrivateRoute";
import BuyCreditsContainer from "../BuyCreditsContainer/";
import FavoriteContainer from "../FavoriteContainer/FavoriteContainer";
import styled from "styled-components";
import HistoryContainer from "../HistoryContainer";
import ProfileContainer from "../ProfileContainer/ProfileContainer";
import PreviewContainer from "../PreviewContainer/PreviewContainer";
import { HIDE_SIGNUP } from "../../config";
import CreateGeneratorContainer from "../CreateGeneratorContainer/CreateGeneratorContainer";

const CenterAlignWrapper = styled.div(() => ({
  display: "flex",
  minHeight: "calc(100vh - 140px)",
  justifyContent: "center",
  alignItems: "center",
}));

const RouteApp = (props) => {
  let location = useLocation();

  let state = location.state;

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Home />} />
          {!HIDE_SIGNUP ? <Route path="/signup" element={<Signup />} /> : null}
          <Route path="/login" element={<Login />} />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordContainer />}
          />
          <Route
            path="/email-action-handler"
            element={<EmailActionHandler />}
          />

          <Route path="generators" element={<Search />} />
          <Route path="buy-credits" element={<BuyCreditsContainer />} />

          <Route path="generator/:id" element={<Details />} />
          <Route
            path="/verify-email"
            element={
              <PrivateRoute>
                <VerifyEmail />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-generator"
            element={
              <PrivateRoute>
                <CreateGeneratorContainer />
              </PrivateRoute>
            }
          />
          <Route
            path="/favorites"
            element={
              <PrivateRoute>
                <FavoriteContainer />
              </PrivateRoute>
            }
          />

          <Route
            path="history"
            element={
              <PrivateRoute>
                <HistoryContainer />
              </PrivateRoute>
            }
          />

          <Route
            path="profile"
            element={
              <PrivateRoute>
                <ProfileContainer />
              </PrivateRoute>
            }
          />

          <Route
            path="/preview/:id"
            element={
              <PrivateRoute>
                <PreviewContainer />
              </PrivateRoute>
            }
          />

          <Route
            path="/success"
            element={
              <PrivateRoute>
                <CenterAlignWrapper>
                  <Result
                    status="success"
                    title="Payment successful."
                    subTitle={
                      <div>
                        Your payment to buy credits was successful.
                        <br /> The updated credits should appear in your account
                        now.
                      </div>
                    }
                    extra={
                      <Link to="/">
                        <Button type="primary">Back Home</Button>
                      </Link>
                    }
                  />
                </CenterAlignWrapper>
              </PrivateRoute>
            }
          />
          <Route
            path="/cancel"
            element={
              <PrivateRoute>
                <Result
                  status="500"
                  title="Cancel"
                  subTitle="Something went wrong. Please try again later."
                  extra={
                    <Link to="/">
                      <Button type="primary">Back Home</Button>
                    </Link>
                  }
                />
              </PrivateRoute>
            }
          />

          {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to="/">
                    <Button type="primary">Back Home</Button>
                  </Link>
                }
              />
            }
          />
        </Route>
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route
            path="/preview/:id"
            element={
              <PrivateRoute>
                <PreviewContainer withModal />
              </PrivateRoute>
            }
          />
        </Routes>
      )}
    </>
  );
};

RouteApp.propTypes = {};

RouteApp.defaultProps = {};

export default RouteApp;
