import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ redirectPath = "/login", children }) => {
  const { userToken } = useSelector((state) => state.user);

  if (!userToken) {
    return <Navigate to={redirectPath} />;
  }
  return children;
};

export default PrivateRoute;
