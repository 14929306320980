import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Category.styles";
import { Divider, Tag, Typography } from "antd";
import Carousel from "../Carousel";
import Card from "../ModelCardWithActions";
import styled from "styled-components";
import { Link } from "react-router-dom";

const { Title } = Typography;

const Wrapper = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Category = ({ loading, items, title = "Category" }) => {
  const children = !loading && items?.length ? items : Array(10).fill({});
  return (
    <Wrapper>
      <Title level={3}>{title}</Title>
      <Divider style={{ margin: "10px 0" }} />
      <Carousel>
        {children.map((child, index) => (
          <Card
            model={child}
            key={index}
            image={child.banner_image}
            title={child.name}
          />
        ))}
      </Carousel>
    </Wrapper>
  );
};

Category.propTypes = {};

Category.defaultProps = {};

export default Category;
