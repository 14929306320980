import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./PreviewContainer.styles";
import { useAxios } from "../../hooks/axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useErrorNotification } from "../../hooks/common";
import PromptHistoryItem from "../../components/PromptHistoryItem/PromptHistoryItem";
import { createGlobalStyle } from "styled-components";
import customTheme from "../../theme-overrides";
import { Modal } from "antd";
import Loader from "../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { downloadImageAction } from "../../features/modelOutput/modelOutputActions";

const GlobalStyle = createGlobalStyle`
.model-history-preview {
.ant-modal-content {
    background-color: ${customTheme.token.colorBgBase};
    overflow: auto;
    min-height: 100vh;
    
  }
  .ant-modal{
    max-width: 100vw;
  }
  .ant-modal-close {
    width: 28px;
    height: 28px;
    padding: 2px;
  }
}
  
`;

const PreviewContainer = ({ withModal }) => {
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const previewImageIndex = Number(searchParams.get("item") || 0);
  const navigate = useNavigate();
  const {
    response = {},
    error,
    loading,
  } = useAxios(
    {
      url: `/api/prompt/${id}/`,
      method: "get",
    },
    { executeOnMount: true }
  );

  useErrorNotification(error);

  const dispatch = useDispatch();

  const onDownload = (data) => {
    dispatch(downloadImageAction(data));
  };

  const component = loading ? (
    <Loader height="100%" />
  ) : (
    response?.prompt && (
      <PromptHistoryItem
        promptDetails={response}
        onDownload={onDownload}
        previewImageIndex={previewImageIndex}
      />
    )
  );

  return withModal ? (
    <>
      <GlobalStyle />
      <Modal
        style={{ top: 0, minHeight: "100vh", paddingBottom: 0 }}
        open
        width={"100vw"}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "25px" }} />}
        onCancel={() => {
          navigate(-1);
        }}
        wrapClassName="model-history-preview"
        maskClosable
        destroyOnClose
      >
        <div
          style={{
            width: "100%",
            minHeight: "calc(100vh - 40px)",
            paddingTop: "50px",
          }}
        >
          {component}
        </div>
      </Modal>
    </>
  ) : (
    <div>{component}</div>
  );
};

PreviewContainer.propTypes = {};

PreviewContainer.defaultProps = {};

export default PreviewContainer;
