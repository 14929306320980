import React from "react";
import { useState, useEffect } from "react";
import { auth } from "../../firebase";
import {
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { Button, Result, Spin, App } from "antd";
import styled from "styled-components";
import ResetPassword from "../../components/ResetPassword";

function getUrlParams() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
}

const LoaderWrapper = styled.div`
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function HandleVerifyEmail({ auth, actionCode, continueUrl, lang }) {
  const [status, setStatus] = useState("loading");
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  // Try to apply the email verification code.
  useEffect(() => {
    applyActionCode(auth, actionCode)
      .then((resp) => {
        // Refresh auth token
        auth?.currentUser.getIdToken(true);
        console.log("Email address has been verified");
        setStatus("success");
        // FIXME: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // FIXME: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        setTimeout(() => {
          window.location = continueUrl;
        }, 2000);
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        setStatus("error");
        console.log(error);
      });
  }, []);
  if (status === "loading") {
    return (
      <LoaderWrapper>
        <Spin size="large" />
      </LoaderWrapper>
    );
  }
  if (status === "error") {
    return (
      <LoaderWrapper>
        <Result
          status="error"
          title="Email verification failed!"
          extra={<Button type="primary">Back Home</Button>}
        />
      </LoaderWrapper>
    );
  }
  return (
    <LoaderWrapper>
      <Result
        status="success"
        title="Email verification successful!"
        extra={<Button type="primary">Back Home</Button>}
      />
    </LoaderWrapper>
  );
}

const HandleResetPassword = ({ auth, actionCode, continueUrl, lang }) => {
  const { message } = App.useApp();

  const [status, setStatus] = useState("loading");
  useEffect(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then((resp) => {
        console.log("Email address has been verified");
        setStatus("success");
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        setStatus("error");
        console.log(error);
      });
  }, []);

  const onResetPassword = (data) => {
    confirmPasswordReset(auth, actionCode, data.password)
      .then((resp) => {
        console.log("resp: ", resp);
        message.success("Password updated successfully!");

        setTimeout(() => {
          window.location = continueUrl;
        }, 2000);
        // Password reset has been confirmed and new password updated.

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
  };

  if (status === "loading") {
    return (
      <LoaderWrapper>
        <Spin size="large" />
      </LoaderWrapper>
    );
  }
  if (status === "error") {
    return (
      <LoaderWrapper>
        <Result
          status="error"
          title="Reset Password failed!"
          extra={<Button type="primary">Back Home</Button>}
        />
      </LoaderWrapper>
    );
  }
  return <ResetPassword onSubmit={onResetPassword} />;
};

function EmailActionHandler() {
  const urlParams = getUrlParams();

  // Get the action to complete.
  const mode = urlParams.get("mode");
  // Get the one-time code from the query parameter.
  const actionCode = urlParams.get("oobCode");
  // (Optional) Get the continue URL from the query parameter if available.
  const continueUrl = urlParams.get("continueUrl") || "/";
  // (Optional) Get the language code if available.
  const lang = urlParams.get("lang") || "en";

  const childProps = { auth, actionCode, continueUrl, lang };
  // Handle the user management action.
  switch (mode) {
    case "verifyEmail":
      // Display email verification handler and UI.
      return <HandleVerifyEmail {...childProps} />;
      break;
    case "resetPassword":
      // Display reset password handler and UI.
      return <HandleResetPassword {...childProps} />;
      break;
    // case 'recoverEmail':
    //   // Display email recovery handler and UI.
    //   handleRecoverEmail(auth, actionCode, lang);
    //   break;
    default:
      console.log("Invalid mode");
  }
}

export default EmailActionHandler;
