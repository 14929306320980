import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Styled from "./ProfileContainer.styles";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileSelector } from "../../features/user/userSlice";
import Profile from "../../components/Profile/Profile";
import { useIsLogin } from "../../hooks/user";
import { message, Spin } from "antd";
import { useErrorNotification } from "../../hooks/common";
import {
  getUserProfile,
  sendPasswordResetEmail,
  updateUserProfile,
} from "../../features/user/userActions";
import { showBuyCreditModal } from "../../features/global/globalSlice";

const ProfileContainer = (props) => {
  const { data, loading, error } = useSelector(getUserProfileSelector);
  const dispatch = useDispatch();
  const isLoggedIn = useIsLogin();
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserProfile());
    }
  }, []);

  useErrorNotification(error);

  if (loading) {
    return <Spin></Spin>;
  }

  const onBuyCredit = () => {
    dispatch(showBuyCreditModal());
  };

  const onProfileChange = (data) => {
    dispatch(updateUserProfile(data));
  };

  const onResetPassword = () => {
    sendPasswordResetEmail(data.email).then(() => {
      message.success(`Recovery link sent successfully to ${data.email}`);
    });
  };

  return (
    <Profile
      {...data}
      onBuyCredit={onBuyCredit}
      onProfileChange={onProfileChange}
      onResetPassword={onResetPassword}
    />
  );
};

ProfileContainer.propTypes = {};

ProfileContainer.defaultProps = {};

export default ProfileContainer;
