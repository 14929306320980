import { API_BASE_URL } from "../config";
import { GENERATION_STATUS } from "../constants";

export function formatPrice(price, currency) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: 0,
  }).format(price);
}

export function extractPaginatedResponse(response) {
  return response.results;
}

export function extractPromptImages(res) {
  if (res && res[0]) {
    return res[0].images.map((img) => ({
      ...img,
      image: `${API_BASE_URL}/${img.image}`,
    }));
  }
  return [];
}

export async function downloadImage(url) {
  const fileName = url.split("/").slice(-1)[0];
  const image = await fetch(url);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function formatStatusQueueData(data) {
  return data.map((item) => {
    const {
      id,
      prompt,
      aimodel: { name: model },
      is_processed = false,
      imagesArr = [],
      images,
      negative_prompt,
    } = item;
    const status = is_processed
      ? GENERATION_STATUS.DONE
      : GENERATION_STATUS.PENDING;
    return {
      data: item,
      key: id,
      id,
      model,
      prompt,
      status:
        is_processed && !imagesArr.length ? GENERATION_STATUS.FAILURE : status,
      imagesArr,
      images,
      negative_prompt,
    };
  });
}

export function filterInprogressItems(data) {
  return data.filter((item) => !item.is_processed);
}

export function debugLog(key, value) {
  window.debugLog && window.debugLog(key, value);
}

export function moveCursorToEnd(elm) {
  let range = null;
  if (document.createRange) {
    range = document.createRange();
    range.selectNodeContents(elm);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

export function formatGenerateImageFormValue(data) {
  const { prompt, negativePrompt, guidanceScale } = data;
  const formattedData = {};
  if (prompt) {
    formattedData.prompt = (
      typeof prompt === "string" ? prompt.split(",") : prompt
    ).filter((tag) => tag.trim());
  }
  if (negativePrompt) {
    formattedData.negativePrompt = (
      typeof negativePrompt === "string"
        ? negativePrompt.split(",")
        : negativePrompt
    ).filter((tag) => tag.trim());
  }
  if (guidanceScale) {
    formattedData.guidanceScale =
      typeof guidanceScale === "string" ? Number(guidanceScale) : guidanceScale;
  }
  return {
    ...data,
    ...formattedData,
  };
}

export const getFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item) => {
        data[key] !== undefined &&
          data[key] !== null &&
          formData.append(key, item);
      });
    } else if (data[key] !== undefined && data[key] !== null) {
      formData.append(key, data[key]);
    }
  });

  return formData;
};
