import { theme } from "antd";
import React from "react";
const { useToken } = theme;

function BorderedDiv({ className, style, children }) {
  const { token } = useToken();
  return (
    <div
      className={className}
      style={{
        borderRadius: token.borderRadius,
        border: `1px solid ${token.colorBorder}`,
        padding: "10px",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default BorderedDiv;
