import React from "react";
import { Upload, Divider } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useState } from "react";

function UploadImage({ fileList = [], ...props }) {
  const handleFileSelection = () => {
    return false;
  };

  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (fileList[0]) {
      setPreview(URL.createObjectURL(fileList[0].originFileObj));
    }
  }, [fileList]);

  return (
    <Upload.Dragger
      beforeUpload={handleFileSelection}
      name="files"
      listType="picture"
      showUploadList={false}
      maxCount={1}
      {...props}
    >
      {preview ? (
        <img src={preview} style={{ width: "auto", maxHeight: "300px" }} />
      ) : (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Drop File Here</p>
          <div style={{ margin: "auto", width: "100px" }}>
            <Divider>or</Divider>
          </div>
          <p className="ant-upload-text">Click to Upload</p>
        </>
      )}
    </Upload.Dragger>
  );
}

export default UploadImage;
