import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./PageLayout.styles";
import { Layout } from "antd";
import AppHeader from "../../components/Header/Header";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const { Content: ContentBase, Footer } = Layout;

const Content = styled(ContentBase)`
  padding-inline: 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding-inline: 20px;
  }
`;

const PageLayout = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <AppHeader />
      <Content>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Pixeous ©2023 Created by DeepKlarity
      </Footer>
    </Layout>
  );
};

PageLayout.propTypes = {};

PageLayout.defaultProps = {};

export default PageLayout;
