import React from "react";
import { Upload, Divider, Modal, Button, Form } from "antd";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useState } from "react";
import Card from "../../components/Card";
import styled from "styled-components";

const Wrapper = styled.div`
  .ant-upload-list-item-container {
    width: auto !important;
    height: auto !important;
  }
  .ant-upload.ant-upload-select {
    width: 190px !important;
    height: 190px !important;
  }
`;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadItem = (originNode, file, fileList, actions) => {
  const { preview, remove } = actions;
  return (
    <Card
      image={file.thumbUrl}
      size="small"
      noTitle
      overlayActions={[
        <Button
          title="Preview"
          onClick={preview}
          type="text"
          icon={<EyeOutlined style={{ fontSize: "1em" }} />}
          size="large"
        />,
        <Button
          title="Remove"
          onClick={remove}
          type="text"
          icon={<DeleteOutlined style={{ fontSize: "1em" }} />}
          size="large"
        />,
      ]}
    />
  );
};

function UploadImage({ fileList, onChange, ...props }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handleFileSelection = () => {
    return false;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url?.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Add Training Images</div>
    </div>
  );

  return (
    <Wrapper>
      <Upload
        beforeUpload={handleFileSelection}
        name="files"
        listType="picture-card"
        fileList={fileList}
        multiple
        onChange={onChange}
        itemRender={UploadItem}
        onPreview={handlePreview}
        {...props}
      >
        {uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Wrapper>
  );
}

export default UploadImage;
