import React from "react";
import { Card } from "antd";
import styled, { css, keyframes } from "styled-components";

const CARD_SIZES = {
  DEFAULT: "240px",
  S: "190px",
  XS: "150px",
  THUMBNAIL: "50px",
};

export const TagWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const TitlePlaceholder = styled.div`
  height: 24px;

  .cardTitle {
    width: calc(100% - 20px);
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 2px;
  }

  .cardDescription {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const AnimateBG = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;

export const CoverImage = styled.div`
  ${({ image, loading, size = "default" }) => css`
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px 5px 0 0;
    transition: height 1.5s ease-in-out;
    background-color: #0c1821;

    ${loading &&
    css`
  background-image: linear-gradient(-45deg, #ee7752, #e73c7e, #6633d9, #7541ea)};
    animation: ${AnimateBG} 5s ease infinite;
    background-size: 400% 400%;
  `}

    ${image
      ? css`
          background-image: url("${image}");
          background-position: center;
        `
      : ""}

    ${size === "small" &&
    css`
      height: ${CARD_SIZES.S};
    `}
  
    ${size === "default" &&
    css`
      height: ${CARD_SIZES.DEFAULT};
      @media (max-width: 600px) {
        height: ${CARD_SIZES.XS};
      }
    `}

    ${size === "thumbnail" &&
    css`
      height: ${CARD_SIZES.THUMBNAIL};
    `}
  `}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
`;

const scaleAnimation = keyframes`
  0% {
    transform: scale(0);
}
100% {
  transform: scale(1);
}
`;

export const StyledCard = styled(Card)`
  ${({ noTitle, flexible, size = "default", animate }) => css`
    padding: 0;
    position: relative;
    transition: width 1s ease-in-out;

    .ant-card-body {
      padding: ${noTitle ? "0" : "12px"};
    }

    .ant-card-meta-title {
      font-size: 14px;
    }

    .ant-card-cover {
      overflow: hidden;
      margin: 0;
      ${noTitle &&
      css`
        border-radius: 6px;
      `}
    }

    ${animate &&
    css`
      transform: scale(0);
      animation: ${scaleAnimation} 450ms cubic-bezier(0.65, 0.05, 0.36, 1)
        forwards;
    `}

    ${flexible &&
    css`
      width: auto;
      @media (max-width: 460px) {
        max-width: 100%;
      }
    `}

    ${size === "small" &&
    css`
      width: ${CARD_SIZES.S};
    `}

    ${size === "default" &&
    css`
      width: ${CARD_SIZES.DEFAULT};
      @media (max-width: 600px) {
        width: ${CARD_SIZES.XS};
      }
    `}

    ${size === "thumbnail" &&
    css`
      width: ${CARD_SIZES.THUMBNAIL};
    `}
  `}

  &:hover {
    ${Overlay} {
      opacity: 1;
    }
  }
`;
