import React from "react";
import { Carousel as AtomsCarousel } from "antd";
import styled, { createGlobalStyle, css } from "styled-components";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

const ChildWrapper = styled.div`
  padding: 0 7px;
`;

const GlobalStyle = createGlobalStyle`
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.9);
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover{
  left: 0;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover{
  right: 0;
}

.ant-carousel .slick-arrow, 
.ant-carousel .slick-arrow {
  line-height: 0;
  position: absolute;
  top: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 10;
  width: 3vw;
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 20px !important;
  height: 100%;
  margin: 0;
  background: rgba(255,255, 255, 0.6);
  opacity: 0;
  transition: all 0.25s ease-in; 
}

.ant-carousel .slick-arrow:hover {
  background: rgba(255,255, 255, 0.6);
  font-size: 28px !important;
}
.ant-carousel:hover .slick-arrow {
  opacity: 1;
}
.ant-carousel .slick-list .slick-track {
  padding: 10px 0;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: auto;
}
${({ showArrows }) =>
  showArrows &&
  css`
    .ant-carousel .slick-arrow {
      opacity: 1;
    }
  `}
`;

const Carousel = ({ children, showArrows }) => {
  return (
    <div>
      <GlobalStyle showArrows={showArrows} />
      <AtomsCarousel
        variableWidth
        dots={false}
        swipeToSlide
        draggable
        arrows
        infinite={children.length > 5}
        slidesToScroll={3}
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
      >
        {React.Children.map(children, (child) => {
          return <ChildWrapper>{child}</ChildWrapper>;
        })}
      </AtomsCarousel>
    </div>
  );
};
export default Carousel;
