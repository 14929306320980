import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const GradientBtn = styled(Button)`
  background: linear-gradient(-45deg, #e73c7e, #6633d9);
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  padding: 10px 20px;
  height: 42px;

  &:hover {
    color: #fff !important;
  }
`;

export default GradientBtn;
