import { theme } from "antd";
const customTheme = {
  // algorithm: theme.darkAlgorithm,
  token: {
    colorBgLayout: "#fefefe",
    // colorBgBase: "#080f15",
    // colorBgBase: "#070f15",
    borderRadius: 5,
    colorText: "rgba(0,0,0,0.7)",
    marginLG: 16,
    // colorPrimary: "#6633d9",
    colorPrimary: "#6f52f0",
    colorInfo: "#6f52f0",
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    Slider: {
      colorPrimaryBorder: "#6f52f0",
      colorPrimaryBorderHover: "#6f52f0",
      colorPrimary: "#6f52f0",
    },
    Collapse: {
      colorFillAlter: "#eaeaea",
    },
    Card: {
      // colorBgContainer: "#ecf0f9",
      colorBgContainer: "#f1f1f1",
    },
    Divider: {
      marginLG: 24,
    },
  },
};

export default customTheme;
