import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Styled from "./BuyCreditsContainer.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditPlans,
  getCurrency,
  setCurrency,
} from "../../features/credits/creditsSlice";
import {
  buyCredits,
  getCreditPlansAction,
} from "../../features/credits/creditsActions";
import { App, Select, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import CreditPlanCard from "../../components/CreditPlanCard/CreditPlanCard";
import { formatPrice } from "../../utils/utils";
import Loader from "../../components/Loader/Loader";

const BuyCreditsContainer = (props) => {
  const { data: creditsPlansData, loading } = useSelector(getCreditPlans);
  const userCurrency = useSelector(getCurrency);
  const { userToken } = useSelector((state) => state.user);
  const {
    pricing_details: creditPlans = [],
    supported_currencies: currencies = [],
  } = creditsPlansData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { message } = App.useApp();
  useEffect(() => {
    dispatch(getCreditPlansAction({ currency: userCurrency }));
  }, []);

  const submitBuyCredits = async ({ id, currency }) => {
    const data = { price_id: id, currency };
    if (userToken) {
      const checkoutUrl = await buyCredits(data);
      window.location.href = checkoutUrl;
    } else {
      message.info("Please login to buy credits.");
      navigate("/login");
    }
  };

  const onCurrencyChange = (currency) => {
    dispatch(setCurrency(currency));
    dispatch(getCreditPlansAction({ currency }));
  };

  if (loading) {
    return <Loader height="100%" />;
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Space direction="vertical" size="large">
        <Typography.Title level={1}>Buy Credits</Typography.Title>
        {currencies?.length ? (
          <Select
            size="large"
            style={{ width: 120 }}
            defaultValue={userCurrency}
            onChange={onCurrencyChange}
            options={currencies.map((currency) => ({
              value: currency,
              label: currency.toUpperCase(),
            }))}
          />
        ) : null}
        <Space
          style={{ margin: "auto", justifyContent: "center" }}
          size="large"
          align="center"
          wrap
        >
          {creditPlans.map((plan) => (
            <CreditPlanCard
              title={plan.name}
              creditCount={plan.credits}
              price={formatPrice(plan.amount, plan.currency)}
              onBuy={() => submitBuyCredits(plan)}
              key={plan.name}
            />
          ))}
        </Space>
      </Space>
    </div>
  );
};

BuyCreditsContainer.propTypes = {};

BuyCreditsContainer.defaultProps = {};

export default BuyCreditsContainer;
