import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginForm from "../../components/Login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../features/user/userActions";
import { clearError, clearTempData } from "../../features/user/userSlice";

const Wrapper = styled.div(() => ({
  display: "flex",
  minHeight: "calc(100vh - 140px)",
  justifyContent: "center",
  alignItems: "center",
}));

const Signup = (props) => {
  const [customError, setCustomError] = useState(null);

  const { loading, userInfo, error, success } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    // redirect user to login page if registration was successful
    if (success || (userInfo && !userInfo?.emailVerified)) {
      navigate("/verify-email", {
        state: {
          intialResendDisable: true,
        },
      });
    }
    if (userInfo?.emailVerified) {
      navigate("/");
    }
  }, [navigate, userInfo, success, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearTempData());
    };
  }, []);

  const submitForm = (data) => {
    // check if passwords match
    // if (data.password !== data.confirmPassword) {
    //   setCustomError('Password mismatch')
    //   return
    // }
    // transform email string to lowercase to avoid case sensitivity issues in login
    // data.email = data.email.toLowerCase()

    dispatch(registerUser(data));
  };

  const onCloseError = () => {
    dispatch(clearError());
  };

  return (
    <Wrapper>
      <LoginForm
        signUp
        onSubmit={submitForm}
        error={error}
        onCloseError={onCloseError}
      />
    </Wrapper>
  );
};

Signup.propTypes = {};

Signup.defaultProps = {};

export default Signup;
