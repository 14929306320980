import styled from "styled-components";

const SectionHolder = styled.div`
  max-width: 1180px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin: auto;
`;

export default SectionHolder;
