import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Styled from "./FavoriteContainer.styles";
import CategoryGrid from "../../components/CategoryGrid";
import { Divider, Empty, Typography } from "antd";
import { useSearchParams } from "react-router-dom";
import { useGetFavoriteModels } from "../../hooks/modelHooks";
import Loader from "../../components/Loader/Loader";

const { Title } = Typography;

const QUERY_PARAMS = {
  PAGE: "page",
};

const getPageNumber = (searchParams) => {
  let page = searchParams.get(QUERY_PARAMS.PAGE) || 1;
  page = Number(page);
  return isNaN(page) || page === 0 ? 1 : page;
};

const FavoriteContainer = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(getPageNumber(searchParams));
  const { getFavoriteModels, data } = useGetFavoriteModels();
  const { loading, data: response } = data;

  const updateSearchParams = (page) => {
    setSearchParams(
      `?${new URLSearchParams({
        page,
      })}`
    );
  };

  useEffect(() => {
    const page = getPageNumber(searchParams);
    getFavoriteModels(page);
    setCurrentPage(page);
  }, [searchParams]);

  const onPageChange = (page) => {
    updateSearchParams(page);
  };

  let result = null;
  const filteredResult = response?.results?.length
    ? response?.results.filter((item) => item.favorite)
    : [];

  useEffect(() => {
    if (
      response?.results?.length &&
      filteredResult.length !== response?.results?.length
    ) {
      if (filteredResult.length === 0 && currentPage > 1) {
        onPageChange(currentPage - 1);
      } else {
        getFavoriteModels(currentPage);
      }
    }
  }, [filteredResult.length]);

  if (loading) {
    result = <Loader height="100%" />;
  } else if (filteredResult.length) {
    result = (
      <CategoryGrid
        items={filteredResult}
        withPagination
        paginationProps={{
          current: currentPage,
          pageSize: response.page_size,
          total: response.count,
          onChange: onPageChange,
        }}
      />
    );
  } else {
    result = <Empty description="No Favorite Models found" />;
  }

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Title level={3}>Favorites</Title>
      </Divider>
      {result}
    </>
  );
};

FavoriteContainer.propTypes = {};

FavoriteContainer.defaultProps = {};

export default FavoriteContainer;
