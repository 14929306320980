import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../user/userSlice";
import {
  getAvailableCreditsAction,
  getCreditPlansAction,
} from "./creditsActions";

const initialState = {
  availableCredits: {
    loading: false,
    error: null,
    data: 0,
  },
  creditPlans: {
    loading: false,
    error: null,
    data: {},
  },
  currency: localStorage.getItem("userCurrency") || "usd",
};

const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
  },
  extraReducers: {
    [getAvailableCreditsAction.pending]: (state) => {
      state.availableCredits.loading = true;
      state.availableCredits.error = null;
    },
    [getAvailableCreditsAction.fulfilled]: (state, { payload }) => {
      state.availableCredits.loading = false;
      state.availableCredits.data = payload;
    },
    [getAvailableCreditsAction.rejected]: (state, { payload }) => {
      state.availableCredits.loading = false;
      state.availableCredits.error = payload;
    },
    [getCreditPlansAction.pending]: (state) => {
      state.creditPlans.loading = true;
      state.creditPlans.error = null;
    },
    [getCreditPlansAction.fulfilled]: (state, { payload }) => {
      state.creditPlans.loading = false;
      state.creditPlans.data = payload;
    },
    [getCreditPlansAction.rejected]: (state, { payload }) => {
      state.creditPlans.loading = false;
      state.creditPlans.error = payload;
    },
    [logout]: (state) => {
      state.availableCredits = initialState.availableCredits;
    },
  },
});

export function getAvailableCreditsCount(state) {
  return state.credits.availableCredits;
}

export function getCreditPlans(state) {
  return state.credits.creditPlans;
}

export function getCurrency(state) {
  return state.credits.currency;
}

export const { setCurrency } = creditsSlice.actions;

export default creditsSlice.reducer;
