import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config";
import {
  registerWithEmailAndPassword,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase.js";
import { ERROR_MAP } from "../../constants/errors";
import http, { httpAuth } from "../../services/http";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const data = await logInWithEmailAndPassword(email, password);
      return data;
    } catch (error) {
      if (error.code) {
        return rejectWithValue(ERROR_MAP[error.code]);
      }
      // return custom error message from API if any
      if (error.response && error.response.data) {
        const errorMap = error.response.data;
        const errorArr = Object.keys(errorMap).reduce(
          (acc, key) => [...acc, ...errorMap[key]],
          []
        );
        return rejectWithValue(errorArr);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const token = await registerWithEmailAndPassword(email, password);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await httpAuth.post(
        `/api/firebase/register-user/`,
        {},
        config
      );
      return response;
    } catch (error) {
      if (error.code) {
        return rejectWithValue(ERROR_MAP[error.code]);
      }
      if (error.response && error.response.data) {
        const errorMap = error.response.data;
        const errorArr = Object.keys(errorMap).reduce(
          (acc, key) => [...acc, ...errorMap[key]],
          []
        );
        return rejectWithValue(errorArr);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await httpAuth.get(`/api/users/me/`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (profileData, { rejectWithValue }) => {
    try {
      await httpAuth.post(`/api/users/update_profile/`, profileData);
      return profileData;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const googleLogin = async () => {
  try {
    const token = await signInWithGoogle();
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const { data } = await http.get(`/api/users/me/`, config);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message;
    } else {
      return error.message;
    }
  }
};

export const sendPasswordResetEmail = async (email) => {
  try {
    const { data } = await http.post(
      `/api/firebase/send-password-reset-email/`,
      { email }
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data.message;
    } else {
      return error.message;
    }
  }
};
