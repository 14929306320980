import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Layout, Menu, theme, Typography } from "antd";
import PropTypes from "prop-types";
import * as Styled from "./Landing.styles";
import { HeaderBase } from "../common/HeaderBase";
import { Link } from "react-router-dom";
import Banner from "./components/Banner";
import Usage from "./components/Usage";
import Features from "./components/Features";
import Examples from "./components/Examples";
import EarlySignup from "./components/EarlySignup";
import SectionHolder from "./components/SectionHolder";
import throttle from "lodash/throttle";
import GradientBtn from "./components/GradientBtn";
import { ParallaxProvider } from "react-scroll-parallax";

const { Content, Footer } = Layout;
const { useToken } = theme;

const commonNav = ["features", "explore", "early-access"];

const getNavItems = () => [
  {
    label: <a href="#features">Features</a>,
    key: "features",
  },
  {
    label: <a href="#explore">Explore</a>,
    key: "explore",
  },
  {
    label: (
      <a href="#early-access">
        <GradientBtn>Get Early Access</GradientBtn>
      </a>
    ),
    key: "early-access",
  },
];

const Landing = (props) => {
  const items = getNavItems();
  const navItems = commonNav.map((nav) => {
    return items.find((item) => item.key === nav);
  });
  const { token } = useToken();
  const [transparentHeader, setTransparentHeader] = useState(true);

  useEffect(() => {
    const updateHeaderBackground = throttle((e) => {
      if (window.scrollY < 100) {
        setTransparentHeader(true);
      } else {
        setTransparentHeader(false);
      }
    }, 500);
    window.addEventListener("scroll", updateHeaderBackground);
    updateHeaderBackground();
    return () => window.removeEventListener("scroll", updateHeaderBackground);
  }, []);

  return (
    <ParallaxProvider>
      <Layout>
        <HeaderBase
          transparent={transparentHeader}
          loading={false}
          navItems={navItems}
          fixed
        ></HeaderBase>
        <Content>
          <Banner></Banner>
          <SectionHolder>
            <Features />
            <Usage />
            <Examples />
          </SectionHolder>
          <EarlySignup />
        </Content>
        <Footer
          style={{
            textAlign: "center",
            background: token.colorPrimary,
            marginTop: "50px",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography.Link
            href="https://discord.gg/tPxFD83PGN"
            target="_blank"
            style={{
              color: "#fff",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Join us on Discord
          </Typography.Link>
          Pixeous ©2023 Created by DeepKlarity
        </Footer>
      </Layout>
    </ParallaxProvider>
  );
};

Landing.propTypes = {};

Landing.defaultProps = {};

export default Landing;
