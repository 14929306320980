import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../features/global/globalSlice";
import http, { httpAuth } from "../services/http";
import { useErrorNotification } from "./common";

export const useAxios = (axiosParams, config = {}) => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    withAuth = true,
    executeOnMount,
    showGlobalLoader,
    errorNotification = true,
  } = config;

  const fetchData = async (params) => {
    try {
      const axios = withAuth ? httpAuth : http;
      const finalParam = { ...axiosParams, ...params };
      if (
        finalParam.transformResponse &&
        Array.isArray(finalParam.transformResponse)
      ) {
        finalParam.transformResponse = [
          ...axios.defaults.transformResponse,
          ...finalParam.transformResponse,
        ];
      }
      setLoading(true);
      setError("");
      showGlobalLoader && dispatch(toggleLoader(true));
      const result = await axios.request(finalParam);
      setResponse(result.data || "Success");
    } catch (error) {
      if (!errorNotification) {
        setError(error);
      }
    } finally {
      setLoading(false);
      showGlobalLoader && dispatch(toggleLoader(false));
    }
  };

  useErrorNotification(errorNotification ? error : null);

  useEffect(() => {
    if (executeOnMount) {
      fetchData(axiosParams);
    }
  }, []);

  return { response, error, loading, fetchData, setResponse };
};
