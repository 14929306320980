import { theme, Typography, Grid, Carousel } from "antd";
import React, { useRef } from "react";
import styled from "styled-components";
import bannerBg from "../../../images/landing-page/pixeous-banner.webp";
import SignupInput from "./SignupInput";
import Product1 from "../../../images/landing-page/assets/product-photos/1.webp";
import Product2 from "../../../images/landing-page/assets/product-photos/2.webp";
import Product3 from "../../../images/landing-page/assets/product-photos/3.webp";
import Product4 from "../../../images/landing-page/assets/product-photos/4.webp";
import Product5 from "../../../images/landing-page/assets/product-photos/13.webp";
import Product6 from "../../../images/landing-page/assets/product-photos/6.webp";
import Product7 from "../../../images/landing-page/assets/product-photos/7.webp";
import Product17 from "../../../images/landing-page/assets/product-photos/17.webp";
//import Product15 from "../../../images/landing-page/assets/product-photos/15.webp";
import Human6 from "../../../images/landing-page/assets/virtual-humans/6.webp";
import Human11 from "../../../images/landing-page/assets/virtual-humans/11.webp";
import Human8 from "../../../images/landing-page/assets/virtual-humans/8.webp";

// import { TypeAnimation } from "react-type-animation";
import { GradientBackground, GradientBgAnimation } from "./styles";
import { useParallax } from "react-scroll-parallax";

const BannerFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    -45deg,
    rgb(231, 60, 126),
    #6633d9,
    #7541ea
  );
  overflow: hidden;
  position: relative;

  > div {
    position: relative;
    z-index: 10;
  }
  :after {
    width: 200%;
    height: 300%;
    position: absolute;
    top: -50%;
    left: -50%;
    content: "";
    display: block;
    background-image: url(${bannerBg});
    background-size: 1800px auto;
    /* transform: rotate(15deg); */
    opacity: 0.3;
    filter: blur(2px);
  }
  .feature-media {
    max-width: 400px;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    order: -1;
    border-radius: 10px;
    margin: 80px auto 10px auto;
    width: 300px;
    height: 300px;
    .feature-media {
      width: 100%;
      img {
        height: auto;
      }
    }
  }
`;

const { useToken } = theme;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-attachment: fixed;
  /* background-image: linear-gradient(-45deg, #858585, #6633d9, #7541ea); */
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  max-height: 900px;
  max-width: 1500px;
  margin: auto;

  .highlighted-text {
    position: relative;
    display: inline-block;

    span {
      position: relative;
      z-index: 1;
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ${GradientBackground}
      animation: ${GradientBgAnimation} 5s ease infinite;
      background-size: 300% 300%;
      &:after {
        -webkit-text-fill-color: #000;
        font-weight: 400;
      }
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      left: 0;
      top: 0;
      background-color: #fff;
    }
  }
  .ant-carousel .slick-list .slick-track {
    padding: 0;
  }

  .banner-text-container {
    max-width: 800px;
    width: 100%;
    color: #fff;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }

  .feature-media {
    border-radius: 10px;
    background-color: #fff;
    backdrop-filter: blur(7px);
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 32.77px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 65.54px 0px,
      rgba(0, 0, 0, 0.03) 0px 0px 131.07px 0px;
    & > * {
      display: block;
      max-width: 100%;
      margin: auto;
    }
  }

  .banner-signup {
    width: 100%;
    max-width: 500px;
    margin-top: 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 2px 20px;
    grid-template-columns: 1fr;
    max-height: auto;
    height: auto;
    .banner-text-container {
      padding: 0px;
      font-size: 30px !important;
      text-align: center;
    }
  }
`;

const images = [
  Product1,
  Product2,
  Human6,
  Product3,
  Product4,
  Human11,
  Product5,
  Product6,
  Human8,
  Product7,
  Product17,
];

function Banner() {
  const { token } = useToken();
  const breakpoint = useBreakpoint();
  const sliderRef = useRef();
  const parallax = useParallax({
    speed: breakpoint.md ? 10 : 0,
  });

  return (
    <Wrapper bgColor={token.colorPrimary}>
      <div className="banner-text-container">
        <div>
          <Typography.Title
            level={1}
            style={{
              color: "#000",
              fontSize: breakpoint.md ? "55px" : "30px",
              marginBottom: "10px",
            }}
          >
            <span>E-commerce </span>
            <br />
            <span className="highlighted-text">
              <span>Product Photoshoot</span>
            </span>{" "}
            made simple.
          </Typography.Title>
          <Typography.Title
            level={breakpoint.md ? 4 : 5}
            style={{ color: "#000000e5", fontWeight: "400", marginTop: "10px" }}
          >
            Visualize your products like never before - Instantly create
            stunning visuals for e-commerce, marketing, social media, and more!
          </Typography.Title>
          <SignupInput className="banner-signup" placeholder="Your email" />
        </div>
      </div>
      <BannerFeature>
        <div className="feature-media" ref={parallax.ref}>
          <Carousel
            fade
            autoplay
            dots={false}
            ref={sliderRef}
            pauseOnHover={false}
          >
            {images.map((img, index) => (
              <img
                src={img}
                key={`bannerImage-${index}`}
                width={400}
                height={400}
              />
            ))}
          </Carousel>
        </div>
      </BannerFeature>
    </Wrapper>
  );
}

export default Banner;
