import { getFormData } from "../utils/utils";
import { httpAuth } from "./http";

export const fetchUsageStatus = () =>
  httpAuth.get("/api/prompt/fair_usage_status/");

export const getModelResponse = (data) =>
  httpAuth.post(
    `/api/prompt/${
      process.env.REACT_APP_MOCK_REQUEST === "true"
        ? "?is_mock_request=True"
        : ""
    }`,
    data
  );

export const generateImgToImg = (data) => {
  const formData = getFormData(data);
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return httpAuth.post(
    `/api/prompt/${
      process.env.REACT_APP_MOCK_REQUEST === "true"
        ? "?is_mock_request=True"
        : ""
    }`,
    formData,
    config
  );
};

export const getModelGeneratedImages = (id) =>
  httpAuth.get(`/api/prompt/${id}/`);

export const downloadImageAPI = (data) =>
  httpAuth.post("/api/prompt/download_image/", data);

export const getHistoryAPI = (page) =>
  httpAuth.get(`/api/prompt/history/?page=${page}`);
