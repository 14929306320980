import React from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import GradientBtn from "./GradientBtn";
import mailImg from "../../../images/landing-page/mail.svg";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    position: relative;
    z-index: 10;
  }
  .join-title {
    margin: 10px 0 !important;
  }
  .join-btn {
    margin-top: 20px;
  }
  .btn-home {
    border-radius: 20px;
    font-weight: 600;
    padding: 10px 20px;
    height: 42px;
    margin-right: 10px;
  }
`;

export const EmailImage = styled.img`
  height: 150px;
  width: auto;
  flex: 0;
  margin-bottom: -20px;
  filter: brightness(1.1) hue-rotate(45deg);
`;

function Join() {
  return (
    <Wrapper>
      <div>
        <EmailImage src={mailImg} alt="mail" />
        <Typography.Title className="join-title" level={2}>
          A welcome mail is flying your way.
        </Typography.Title>
        <Typography.Title className="join-title" level={5}>
          While you wait, come and join our Discord community and introduce
          yourself:
        </Typography.Title>
        <div className="btn-wrapper">
          <Link to="/">
            <Button className="btn-home">Back to Home</Button>
          </Link>
          <GradientBtn
            type="link"
            href="https://discord.gg/tPxFD83PGN"
            className="join-btn"
            target="_blank"
          >
            Join Discord
          </GradientBtn>
        </div>
      </div>
    </Wrapper>
  );
}

export default Join;
