import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Details.styles";
import ModelDetails from "../../components/ModelDetails/ModelDetails";
import { useGetModelDetail, useGetQueue } from "../../hooks/modelHooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const Details = (props) => {
  const { getModelDetail, data: modelResponse } = useGetModelDetail();
  let { id } = useParams();
  const { data, loading } = modelResponse;

  const { getQueue } = useGetQueue();

  const refreshQueue = () => {
    getQueue(1);
  };

  useEffect(() => {
    getModelDetail(id);
  }, [id]);

  if (loading) {
    return (
      <LoaderWrapper>
        <Spin />
      </LoaderWrapper>
    );
  }
  return data ? (
    <ModelDetails
      {...data}
      onSubmitPrompt={refreshQueue}
      onImageResponse={refreshQueue}
    />
  ) : null;
};

Details.propTypes = {};

Details.defaultProps = {};

export default Details;
