import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./Debug.styles";
import { useSelector } from "react-redux";
import { Button, Descriptions, Typography } from "antd";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
const { Text } = Typography;

const Wrapper = styled.div`
  /* position: fixed; */
  top: 0px;
  left: 0px;
  background-color: #000;
  min-width: 300px;
  height: auto;
  z-index: 10000;
  max-width: 100%;
  max-height: 500px;
  overflow: auto;
`;

const Debug = (props) => {
  const userData = useSelector((state) => state.user);
  const [logs, setLogs] = useState([]);

  const addLog = (key, value) => {
    console.log({ [key]: value });
    setLogs((logs) => [...logs, { key, value }]);
  };

  useEffect(() => {
    window.debugLog = addLog;
  }, []);

  return (
    <Wrapper>
      <Descriptions bordered>
        <Descriptions.Item label="Token">
          <Text copyable code>
            {userData.userToken}
          </Text>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        bordered
        title="debug logs"
        column={1}
        extra={
          <Button type="primary" onClick={() => setLogs([])}>
            Clear
          </Button>
        }
      >
        {logs.map(({ key, value }) => (
          <Descriptions.Item label={key}>
            <Text code>
              <pre>
                {typeof value === "object"
                  ? JSON.stringify(value, null, 4)
                  : value}
              </pre>
            </Text>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Wrapper>
  );
};

Debug.propTypes = {};

Debug.defaultProps = {};

export default Debug;
