import React, { useState } from "react";
import { Button, Layout, Menu, Drawer, Dropdown, theme } from "antd";
import styled, { createGlobalStyle, css } from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import logoPic from "../../images/pix.png";

const { Header } = Layout;
const { useToken } = theme;

export const GlobalStyles = createGlobalStyle`
.menuDrawer .profileCaretIcon {
    display: none;
  }
  .menuDrawer .creditNavItem {
    display: block;
  }
  .menuDrawer .creditNavItem {
    display: none;
  }
`;

export const Wrapper = styled(Header)`
  ${({ transparent, fixed, bgColor }) => css`
     {
      position: ${fixed ? "fixed" : "sticky"};
      width: 100%;
      top: 0px;
      left: 0px;
      z-index: 200;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${transparent ? "transparent" : bgColor} !important;
      transition: background-color 350ms ease-in-out;

      ${fixed &&
      css`
        max-width: 1500px;
        left: 50%;
        transform: translateX(-50%);
      `}

      .bigmenu {
        display: none;

        .user-nav-item-dropdown {
          margin: 0 20px;
          cursor: pointer;
        }
        .creditNavItem {
          margin: 8px 10px;
        }
      }

      &.ant-layout-header {
        padding-inline: 20px;
      }

      @media (min-width: 990px) {
        .bigmenu {
          display: flex;
        }
        .creditNavItem-mobile {
          display: none;
        }
        .menubtn {
          display: none;
        }
        &.ant-layout-header {
          padding-inline: 50px;
        }
      }
    }
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  gap: 10px;
  opacity: ${(p) => (p.loading ? 0 : 1)};
`;

export const MenuItem = styled.div((props) => ({
  color: props.selected ? props.selectedColor : props.color,
  fontSize: "15px",
  fontWeight: "600",
  "&:hover, &:hover a": {
    color: props.selectedColor,
  },
  "& a": {
    padding: "8px 20px",
    color: props.selected ? props.selectedColor : props.color,
  },
}));

export const AlertContent = styled.div`
  max-width: 1100px;
  margin: auto;
  text-align: center;
`;

export const Logo = styled.img`
  height: 21px;
  width: auto;
  flex: 0;
`;

export function HeaderBase(props) {
  const [current, setCurrent] = useState();
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const { token } = useToken();
  const { transparent, fixed } = props;

  const closeDrawer = () => {
    setVisible(false);
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    closeDrawer();
  };
  return (
    <>
      <GlobalStyles />
      <Wrapper
        bgColor={token.colorBgBase}
        transparent={transparent}
        fixed={fixed}
      >
        <Link to="/">
          <Logo src={logoPic} alt="logo" />
        </Link>
        <Button
          className="menubtn"
          icon={<MenuOutlined />}
          onClick={showDrawer}
        ></Button>
        <Drawer
          title={<Logo src={logoPic} alt="logo" />}
          placement="right"
          onClose={closeDrawer}
          open={visible}
          className="menuDrawer"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Menu
              onClick={handleClick}
              selectedKeys={[current]}
              mode="inline"
              items={props.navItems}
              style={{
                backgroundColor: "transparent",
                borderInlineEnd: "none",
              }}
            />
          </div>
        </Drawer>
        <MenuWrapper className="bigmenu" loading={props.loading}>
          {props.navItems.map((item) =>
            !item.children ? (
              <MenuItem
                key={item.key}
                selectedColor={transparent ? "#fff" : token.colorPrimary}
                color={transparent ? "#fff" : token.colorText}
                onClick={() => handleClick(item.key)}
                selected={current === item.key}
              >
                {item.label}
              </MenuItem>
            ) : (
              <>
                <Dropdown
                  key={item.key}
                  menu={{
                    items: item.children,
                  }}
                  placement="bottomRight"
                  overlayStyle={{
                    minWidth: "150px",
                  }}
                  trigger="click"
                  getPopupContainer={(node) => node}
                >
                  {item.label}
                </Dropdown>
              </>
            )
          )}
        </MenuWrapper>
      </Wrapper>
    </>
  );
}
