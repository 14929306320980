import { css, keyframes } from "styled-components";

export const GradientBackground = css`
  background-image: linear-gradient(-45deg, #ee7752, #e73c7e, #6633d9, #7541ea);
`;

export const GradientBgAnimation = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;
